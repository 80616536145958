import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  Building2,
  Store,
  Users,
  Wifi,
  ShoppingCart,
  RefreshCw,
  LogOut,
  ChevronsUpDown,
  Search,
  ChevronRight,
  Home,
  Check,
} from "lucide-react";
import { RootState } from "./store";
import { Badge } from "@/components/ui/badge";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from "@/components/ui/sidebar";
import { ThemeToggle } from "./components/ThemeToggle/ThemeToggle";
import { Logo } from "./components/Logo/Logo";
import { cn } from "./lib/utils";
import { CommandMenu } from "./components/CommandMenu/CommandMenu";
import { Button } from "./components/ui/button";

const parseNameFromEmail = (email: string) => {
  const localPart = email.split("@")[0];
  const nameParts = localPart.split(".");

  if (nameParts.length >= 2) {
    const firstName =
      nameParts[0].charAt(0).toUpperCase() + nameParts[0].slice(1);
    const lastName =
      nameParts[1].charAt(0).toUpperCase() + nameParts[1].slice(1);
    return { firstName, lastName };
  }

  return null;
};

const NavUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userEmail = useSelector((state: RootState) => state.user.account?.ema);
  const parsedName = userEmail ? parseNameFromEmail(userEmail) : null;

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch({ type: "RESET_APP" });
    navigate("/login");
  };

  if (!userEmail) return null;

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size="lg"
              className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
            >
              <Avatar className="h-8 w-8 rounded-lg">
                <AvatarImage
                  alt={
                    parsedName
                      ? `${parsedName.firstName} ${parsedName.lastName}`
                      : userEmail
                  }
                />
                <AvatarFallback className="rounded-lg">
                  {parsedName
                    ? `${parsedName.firstName[0]}${parsedName.lastName[0]}`
                    : userEmail.substring(0, 2).toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <div className="grid flex-1 text-left text-sm leading-tight">
                {parsedName ? (
                  <span className="truncate font-semibold">
                    {`${parsedName.firstName} ${parsedName.lastName}`}
                  </span>
                ) : null}
                <span className="truncate text-xs">{userEmail}</span>
              </div>
              <ChevronsUpDown className="ml-auto size-4" />
            </SidebarMenuButton>
          </DropdownMenuTrigger>

          <DropdownMenuContent className="w-56" align="end" side="right">
            <DropdownMenuItem onClick={handleLogout}>
              <LogOut className="mr-2 h-4 w-4" />
              Logout
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  );
};

const NavBreadcrumbs = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter((path) => path);

  const getTitle = (path: string) => {
    return path.charAt(0).toUpperCase() + path.slice(1);
  };

  // Skip breadcrumbs if we're on the home page
  if (paths.length === 0) return null;

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbItem>{" > "}Theresa</BreadcrumbItem>
        {paths.map((path, index) => (
          <BreadcrumbItem key={path}>
            <BreadcrumbSeparator>
              <ChevronRight className="h-4 w-4" />
            </BreadcrumbSeparator>
            {index === paths.length - 1 ? (
              <BreadcrumbPage>{getTitle(path)}</BreadcrumbPage>
            ) : (
              <BreadcrumbLink asChild>
                <Link to={`/${paths.slice(0, index + 1).join("/")}`}>
                  {getTitle(path)}
                </Link>
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const NavMain = () => {
  const location = useLocation();

  const navItems = [
    {
      title: "Organisations",
      icon: Building2,
      href: "/organisations",
    },
    {
      title: "Stores",
      icon: Store,
      href: "/stores",
    },
    {
      title: "Accounts",
      icon: Users,
      href: "/accounts",
    },
    {
      title: "Status",
      icon: Wifi,
      href: "/status",
    },
    {
      title: "Syncing",
      icon: RefreshCw,
      href: "/syncing",
    },
    {
      title: "Orders",
      icon: ShoppingCart,
      href: "/orders",
    },
    {
      title: "Tracking Parameters",
      icon: Check,
      href: "/tracking-parameters",
    },
  ];

  return (
    <SidebarGroup>
      <SidebarGroupLabel>Navigation</SidebarGroupLabel>
      <SidebarMenu>
        {navItems.map((item) => {
          const isActive = location.pathname.startsWith(item.href);

          return (
            <SidebarMenuItem key={item.href}>
              <SidebarMenuButton
                asChild
                className={cn(
                  isActive && "bg-primary text-white",
                  "transition-colors"
                )}
              >
                <Link to={item.href}>
                  {item.icon && <item.icon className="h-4 w-4" />}
                  <span>{item.title}</span>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          );
        })}
      </SidebarMenu>
    </SidebarGroup>
  );
};

export function AppSidebar() {
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const environment = backendUrl.startsWith("https://hive.tracify.ai")
    ? "production"
    : backendUrl.startsWith("https://devhive.tracify.ai")
    ? "development"
    : "local";

  const { state } = useSidebar();
  const collapsed = state === "collapsed";

  return (
    <Sidebar collapsible="icon">
      <SidebarHeader>
        <div className="px-3 py-2">
          <div className="space-y-1 justify-center flex flex-col items-center">
            <Logo size={collapsed ? 24 : 32} />
            <Badge
              variant={environment === "production" ? "rose" : "blue"}
              className={cn(
                "truncate max-w-full",
                collapsed && "flex justify-center"
              )}
            >
              {collapsed
                ? environment.charAt(0).toUpperCase()
                : environment.charAt(0).toUpperCase() + environment.slice(1)}
            </Badge>
          </div>
        </div>
      </SidebarHeader>
      <SidebarContent>
        <NavMain />
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}

export function Layout({ children }: { children: React.ReactNode }) {
  const [commandOpen, setCommandOpen] = React.useState(false);

  return (
    <div className="flex min-h-screen min-w-full flex-col">
      <div className="flex flex-1 z-[50]">
        <AppSidebar />

        <main className="flex-1 overflow-auto min-w-[1200px]">
          <header className="sticky top-0 z-50 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
            <div className="w-full flex h-14 items-center px-6">
              <div className="flex flex-1 items-center justify-between space-x-4">
                <div className="flex items-center space-x-4">
                  <NavBreadcrumbs />
                </div>
                <div className="flex items-center space-x-2 pr-20">
                  <div className="w-[240px]">
                    <Button
                      variant="outline"
                      className="inline-flex items-center rounded-md font-normal w-full justify-between bg-muted/50"
                      onClick={() => setCommandOpen(true)}
                    >
                      <div className="inline-flex">
                        <Search className="mr-2 h-4 w-4" />
                        Search or create...
                      </div>
                      <kbd className="pointer-events-none inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground ml-4">
                        <span className="text-xs">⌘</span>K
                      </kbd>
                    </Button>
                  </div>
                  <ThemeToggle />
                </div>
              </div>
            </div>
          </header>

          <div className="container p-6">{children}</div>
        </main>
      </div>
      <CommandMenu open={commandOpen} onOpenChange={setCommandOpen} />
    </div>
  );
}
