import { useEffect, useMemo, useState } from "react";
import { batch, useSelector } from "react-redux";
import {
  deleteStore,
  listStores,
  createStoreServerToken,
  updateStore,
} from "./reducers/stores";
import { RootState, useAppDispatch as useDispatch } from "./store";
import React from "react";
import { CodeBlock, nord } from "react-code-blocks";
import {
  Account,
  AdAccount,
  APIError,
  ApprovalCreateResponse,
  Organisation,
  OrganisationCreateResponse,
  ShopifyIntegration,
  Siteref,
  SiterefCreateResponse,
  Store,
  StoreServerTokenCreateResponse,
  UTM,
} from "./backendTypes";
import { Link, useNavigate, useParams } from "react-router-dom";
import { listAccounts } from "./reducers/accounts";
import dayjs from "dayjs";
import {
  createApproval,
  listApprovals,
  deleteApproval,
} from "./reducers/approvals";
import TUtils from "./TUtils";
import {
  createSiteref,
  deleteSiteref,
  listSiterefs,
} from "./reducers/siterefs";
import {
  listOrganisations,
  updateOrganisation,
} from "./reducers/organisations";
import {
  createShopifyIntegration,
  deleteShopifyIntegration,
  getShopifyIntegration,
  listIntegrations,
  updateShopifyIntegration,
} from "./reducers/integrations";
import { listUtms } from "./reducers/utms";

import { shopSystemOptions } from "./ClientStores";
import { listAdAccounts } from "./reducers/ad-accounts";
import { DateTime } from "luxon";
import { Card, CardContent, CardHeader, CardTitle } from "./components/ui/card";
import {
  AlertCircle,
  CheckCircle,
  CircleX,
  Copy,
  Delete,
  Eye,
  EyeOff,
  PencilIcon,
  PlusCircle,
  Search,
  Unlink,
  UserMinus,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./components/ui/tooltip";
import { Alert, AlertDescription } from "./components/ui/alert";
import { Badge } from "./components/ui/badge";
import { Button } from "./components/ui/button";
import { Label } from "./components/ui/label";
import { toast } from "sonner";
import { Input } from "./components/ui/input";
import { RadioGroup, RadioGroupItem } from "./components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./components/ui/table";
import { Switch } from "./components/ui/switch";
import { Textarea } from "./components/ui/textarea";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./components/ui/dialog";
import ComboboxMultiSelect from "./components/ComboboxMultiSelectProps/ComboboxMultiSelectProps";
import {
  getCheckoutCode,
  getPostPurchaseCode,
  getPrettyRefType,
  getTrackingCode,
  getURLParams,
  getWebsiteURL,
} from "./lib/client-utils";
import { Checkbox } from "./components/ui/checkbox";
import { Separator } from "./components/ui/separator";
import LinkedTableCell from "./components/LinkedTableCell/LinkedTableCell";

function findStore(csid: string | undefined, stores: Array<Store>) {
  const c = stores.filter((store) => store.csid === csid);
  return c.length == 0 ? undefined : c[0];
}

function findAccount(
  acid: string | undefined,
  accounts: Array<Account> | undefined
) {
  if (accounts === undefined) return undefined;
  const a = accounts.filter((account) => account.acid === acid);
  return a.length == 0 ? undefined : a[0];
}

function ClientStore() {
  const [loadingUtmAccounts, setLoadingUtmAccounts] = useState<Set<string>>(
    new Set()
  );
  const [utmIssuesCounts, setUtmIssuesCounts] = useState<
    Record<string, number>
  >({});
  const scope = "kytron::read_events";
  const manageOrganisationsApprovalScope = "manage_organisations";
  const deleteStoresScope = "kytron::delete_customersite";
  const updateStoresScope = "kytron::update_customersite";
  const { csid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  const shopconnectorBackendUrl = useSelector(
    (state: RootState) => state.environment.shopconnectorBackendUrl
  );
  const adconnectorBackendUrl = useSelector(
    (state: RootState) => state.environment.adconnectorBackendUrl
  );

  const session = useSelector((state: RootState) => state.user.session);
  const account = useSelector((state: RootState) => state.user.account);
  const stores = useSelector((state: RootState) => state.stores.stores);
  const accounts = useSelector((state: RootState) => state.accounts.accounts);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations
  );
  const integrations = useSelector(
    (state: RootState) => state.integrations.integrations
  );
  const approvals = useSelector(
    (state: RootState) => state.approvals.approvals
  );
  const siterefs = useSelector((state: RootState) => state.siterefs.siterefs);
  const adAccountsState = useSelector(
    (state: RootState) => state.adAccounts.adAccounts
  );
  const utms = useSelector((state: RootState) => state.utms.utms ?? []);

  const selectedStore = useSelector((state: RootState) =>
    findStore(csid, state.stores.stores)
  );
  const [storeName, setStoreName] = useState(selectedStore?.name ?? "");
  const [storeUrl, setStoreUrl] = useState(selectedStore?.url ?? "");
  const [storeDescription, setStoreDescription] = useState(
    selectedStore?.description ?? ""
  );
  const [isStoreActive, setIsStoreActive] = useState(
    selectedStore?.active ?? false
  );
  const [editDlgOpen, setEditDlgOpen] = useState(false);
  const getSourceFromRefType = (reftype: string): string => {
    const sourceMap: Record<string, string> = {
      google: "google",
      fb: "facebook",
      tiktok: "tiktok",
      pinterest: "pinterest",
      outbrain: "outbrain",
    };

    return sourceMap[reftype] || reftype;
  };

  const handleClose = () => {
    setStoreName(selectedStore?.name ?? "");
    setStoreDescription(selectedStore?.description ?? "");
    setStoreUrl(selectedStore?.url ?? "");
    setIsStoreActive(selectedStore?.active ?? false);
    setEditDlgOpen(false);
  };
  const handleClickOpen = () => {
    setStoreName(selectedStore?.name ?? "");
    setStoreDescription(selectedStore?.description ?? "");
    setStoreUrl(selectedStore?.url ?? "");
    setIsStoreActive(selectedStore?.active ?? false);
    setEditDlgOpen(true);
  };

  const [selectedOrganisation, setSelectedOrganisation] =
    useState<Organisation | null>();

  const handleUpdate = () => {
    dispatch(listStores({ scope, session, backendUrl }));
    dispatch(listApprovals({ session, backendUrl }));
    dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    dispatch(
      listIntegrations({ session, backendUrl: shopconnectorBackendUrl })
    );
    dispatch(listSiterefs({ session, backendUrl }));
    dispatch(listUtms({ session, backendUrl: adconnectorBackendUrl }));
  };

  useEffect(() => {
    if (organisations && stores && selectedStore) {
      const organisation = organisations.find((orga) =>
        orga.customer_sites.find((el) => el.id === selectedStore?.csid)
      );
      setSelectedOrganisation(organisation ?? null);
    }
  }, [organisations, stores, selectedStore]);

  useEffect(() => {
    if (stores.length == 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
    if (accounts.length == 0) {
      dispatch(listAccounts({ session, backendUrl }));
    }
    if (organisations.length == 0) {
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl }));
    }
    if (integrations.length == 0) {
      dispatch(
        listIntegrations({ session, backendUrl: shopconnectorBackendUrl })
      );
    }
    if (approvals.length == 0) {
      dispatch(listApprovals({ session, backendUrl }));
    }
    if (siterefs.length == 0) {
      dispatch(listSiterefs({ session, backendUrl }));
    }
    if (adAccountsState?.length == 0) {
      dispatch(listAdAccounts({ session, backendUrl: adconnectorBackendUrl }));
    }
  }, []);

  const getUtmStatus = (accountId: string) => {
    if (loadingUtmAccounts.has(accountId)) {
      return { loading: true };
    }

    if (accountId in utmIssuesCounts) {
      const issueCount = utmIssuesCounts[accountId];
      return {
        hasIssues: issueCount > 0,
        issueCount,
      };
    }

    return null;
  };

  const navigateToUtmDashboard = (accountId: string, reftype: string) => {
    const searchParams = new URLSearchParams();
    searchParams.set("search", accountId);
    searchParams.set("source", getSourceFromRefType(reftype));

    navigate(`/tracking-parameters?${searchParams.toString()}`);
  };

  const manageOrgasApproval = useMemo(
    () =>
      !!account?.scp.any?.find((el) => el === manageOrganisationsApprovalScope),
    [account]
  );

  const updateStoreApproval = useMemo(
    () => !!account?.scp.any?.find((el) => el === updateStoresScope),
    [account]
  );

  const deleteStoreApproval = useMemo(
    () => !!account?.scp.any?.find((el) => el === deleteStoresScope),
    [account]
  );

  const [selectedAccountMail, setSelectedAccountMail] = useState("");
  const [addUserDlgOpen, setAddUserDlgOpen] = useState(false);
  const [addOrganisationDlgOpen, setAddOrganisationDlgOpen] = useState(false);
  const [addSiterefDlgOpen, setAddSiterefDlgOpen] = useState(false);
  const [deleteStoreDlgOpen, setDeleteStoreDlgOpen] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [shopSystem, setShopSystem] = useState<
    (typeof shopSystemOptions)[number] | undefined
  >(shopSystemOptions.find((el) => el.value === selectedStore?.shop_system));
  const [refid, setRefid] = useState("");
  const [reftype, setReftype] = useState("google");
  const [searchUser, setSearchUser] = useState("");
  const [tokenModalOpen, setTokenModalOpen] = useState(false);
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [ssTokenUser, setSsTokenUser] = useState("");
  const [ssToken, setSsToken] = useState("");
  const [shopType, setShopType] = useState("shopify");
  const [enableFP, setEnableFP] = useState(true);
  const [trackingChannel, setTrackingChannel] = useState("production");
  const [trackingCode, setTrackingCode] = useState(
    getTrackingCode(
      shopType,
      enableFP,
      trackingChannel,
      backendUrl,
      selectedStore?.csid
    )
  );
  const [checkoutCode, setCheckoutCode] = useState(
    getCheckoutCode(shopType, backendUrl, selectedStore?.csid)
  );
  const [ppsCode, setPPSCode] = useState(
    getPostPurchaseCode(
      shopType,
      trackingChannel,
      backendUrl,
      selectedStore?.csid
    )
  );

  const [adsProvider, setAdsProvider] = useState("meta");
  const [useRedirectGateway, setUseRedirectGateway] = useState(false);
  const [landingPageURL, setLandingPageURL] = useState(
    TUtils.validateURL(selectedStore?.url)
  );
  const [websiteURL, setWebsiteURL] = useState(selectedStore?.url);
  const [URLParameters, setURLParameters] = useState(
    getURLParams(
      adsProvider,
      landingPageURL,
      selectedStore?.csid ?? "",
      useRedirectGateway
    )
  );
  const [domain, setDomain] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [APIKey, setAPIKey] = useState("");
  const [APISecret, setAPISecret] = useState("");
  const [fingerprinting, setFingerprinting] = useState(false);
  const [betaFeatures, setBetaFeatures] = useState(false);
  const [cookie, setCookie] = useState("disabled");
  const [integrationToUpdate, setIntegrationToUpdate] = useState("");
  const [snippet, setSnippet] = useState("second");
  const relevantOrganisation = useMemo(() => {
    if (organisations && stores && selectedStore) {
      const organisation = organisations.find((orga) =>
        orga.customer_sites.find((el) => el.id === selectedStore?.csid)
      );
      return organisation ?? null;
    } else return null;
  }, [organisations, stores, selectedStore]);

  const relevantApprovals = approvals.filter(
    (x) => x.rsid === selectedStore?.csid
  );
  const [approvedUsers, setApprovedtUsers] = useState<Array<Account>>(
    relevantApprovals
      .map((x) => findAccount(x.acid, accounts))
      .filter((el) => el !== undefined) as Account[]
  );
  const relevantUsers = accounts.filter(
    // TODO: can anybody explain what these role magic numbers mean?
    (x) =>
      x.role !== 128 &&
      x.role !== 255 &&
      // inactive users may not be linked to a store
      x.active &&
      findAccount(x.acid, approvedUsers) === undefined
  ) as Array<Account>;

  const relevantOrganisationScriptType =
    relevantOrganisation?.customer_sites[0].detected_script_type;

  useEffect(() => {
    const filteredApprovals = relevantApprovals
      .map((x) => findAccount(x.acid, accounts))
      .filter((el) => el !== undefined) as Account[];
    if (searchUser.length !== 0) {
      setApprovedtUsers(
        filteredApprovals.filter((approval) =>
          approval.email?.toLowerCase().includes(searchUser.toLowerCase())
        )
      );
    } else {
      setApprovedtUsers(filteredApprovals);
    }
  }, [approvals, searchUser, accounts]);

  const relevantSiterefs = useMemo(() => {
    const relevant = siterefs.filter((x) => x.csid === selectedStore?.csid);

    const siterefsWithAccount = relevant.map((el) => {
      const account = adAccountsState.find((x) => x.account_id === el.refid);
      if (account) {
        return { ...el, ...account };
      }
      return el;
    });
    return siterefsWithAccount as (Siteref & AdAccount)[];
  }, [adAccountsState, siterefs, selectedStore]);
  const relevantIntegrations = integrations?.filter(
    (x) => x.csid === selectedStore?.csid
  );

  useEffect(() => {
    if (relevantSiterefs.length > 0) {
      const newLoadingAccounts = new Set<string>();

      relevantSiterefs.forEach((ref) => {
        newLoadingAccounts.add(ref.refid);
      });

      setLoadingUtmAccounts(newLoadingAccounts);

      const processedCombinations = new Set<string>();

      relevantSiterefs.forEach((ref) => {
        const combinationKey = `${ref.refid}-${ref.reftype}`;

        const source = getSourceFromRefType(ref.reftype);

        if (!processedCombinations.has(combinationKey)) {
          processedCombinations.add(combinationKey);
          const filters = {
            by_account_id: ref.refid,
            by_sources: [source],
          };

          dispatch(
            listUtms({
              session,
              backendUrl: adconnectorBackendUrl,
              filters: filters,
              abortController: false,
            })
          )
            .then((response: any) => {
              if (response && response.payload) {
                const payload = response.payload as {
                  items: UTM[];
                  totalCount: number;
                };
                if (payload.totalCount !== undefined) {
                  setUtmIssuesCounts((prev) => ({
                    ...prev,
                    [ref.refid]: payload.totalCount,
                  }));
                }
              }
            })
            .finally(() => {
              setLoadingUtmAccounts((current) => {
                const updated = new Set(current);
                updated.delete(ref.refid);
                return updated;
              });
            });
        }
      });
    } else {
      setLoadingUtmAccounts(new Set());
    }
  }, [relevantSiterefs.length]);

  const updateTrackingCodes = (
    shopType: string,
    enableFP: boolean,
    trackingChannel: string
  ) => {
    setTrackingCode(
      getTrackingCode(shopType, enableFP, trackingChannel, backendUrl, csid)
    );
    setCheckoutCode(getCheckoutCode(shopType, backendUrl, selectedStore?.csid));
    setPPSCode(
      getPostPurchaseCode(shopType, trackingChannel, backendUrl, csid)
    );
  };

  const handleShopTypeChange = (value: string) => {
    setShopType(value);
    updateTrackingCodes(value, enableFP, trackingChannel);
  };

  const handleChangeFP = (checked: boolean) => {
    setEnableFP(checked);
    updateTrackingCodes(shopType, checked, trackingChannel);
  };

  const handleTrackingChannelChange = (newChannel: string) => {
    setTrackingChannel(newChannel);
    updateTrackingCodes(shopType, enableFP, newChannel);
  };

  const handleAddOrganisation = () => {
    setAddOrganisationDlgOpen(true);
  };

  const handleAddOrganisationClose = () => {
    setAddOrganisationDlgOpen(false);
  };

  const handleAddUser = () => {
    setAddUserDlgOpen(true);
  };

  const handleAddUserClose = () => {
    setAddUserDlgOpen(false);
  };

  const handleRemoveStoreFromOrganisationAccounts = (accountIds: string[]) => {
    const accountApprovals = approvals
      .filter((el) => {
        return (
          el.scope === "kytron::read_events" &&
          el.rsid === selectedStore?.csid &&
          accountIds.includes(el.acid)
        );
      })
      .map((el) => el.asi);
    if (accountApprovals.length > 0) {
      dispatch(
        deleteApproval({
          asis: accountApprovals,
          session,
          backendUrl,
        })
      );
    }
  };
  const handleAddStoreToOrganisationAccounts = (accountIds: string[]) => {
    if (accountIds.length > 0 && selectedStore) {
      batch(() => {
        accountIds.map((el) => {
          dispatch(
            createApproval({
              rsid: selectedStore.csid,
              scope: "kytron::read_events",
              acid: el,
              rstype: 0,
              session,
              backendUrl,
            })
          );
        });
      });
    }
  };

  const handleAddOrganisationToStore = () => {
    if (!selectedOrganisation) return;
    if (relevantOrganisation?.id === selectedOrganisation?.id) {
      return;
    }
    if (relevantOrganisation && selectedStore) {
      const indexInOrga = relevantOrganisation.customer_sites.findIndex(
        (el) => el.id === selectedStore.csid
      );
      const removedStores =
        indexInOrga !== -1
          ? [
              ...relevantOrganisation.customer_sites.slice(0, indexInOrga),
              ...relevantOrganisation.customer_sites.slice(indexInOrga + 1),
            ]
          : relevantOrganisation.customer_sites;
      dispatch(
        updateOrganisation({
          id: relevantOrganisation.id,
          name: relevantOrganisation?.name,
          client_number: relevantOrganisation?.client_number,
          feature_flags: relevantOrganisation?.feature_flags,
          customer_sites: removedStores,
          accounts:
            relevantOrganisation.accounts?.map((el) => ({ id: el.id })) ?? [],
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then(() => {
          setAddOrganisationDlgOpen(false);
          handleRemoveStoreFromOrganisationAccounts(
            relevantOrganisation.accounts.map((el) => el.id)
          );

          // this is very bad, but since these batch dispatch actions in redux are not async
          // we can't await them which will lead to race condition so we give them a 1s headstart
          // when refactoring theresa, please change
          setTimeout(() => {
            handleUpdate();
          }, 1000);
          toast.success(
            `Store removed from organisation '${selectedOrganisation?.name}'`
          );
        })
        .catch((err: APIError) => {
          toast.error(
            `Unable to remove store to organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
    if (selectedOrganisation && selectedStore) {
      // we changed the organisation for this store
      dispatch(
        updateOrganisation({
          id: selectedOrganisation.id,
          name: selectedOrganisation?.name,
          client_number: selectedOrganisation?.client_number,
          feature_flags: selectedOrganisation?.feature_flags,
          customer_sites: [
            ...(selectedOrganisation?.customer_sites?.map((el) => ({
              id: el.id,
            })) ?? []),
            { id: selectedStore?.csid },
          ],
          accounts:
            selectedOrganisation.accounts?.map((el) => ({ id: el.id })) ?? [],
          session,
          backendUrl: chiefBackendUrl,
        })
      )
        .unwrap()
        .then((createResponse: OrganisationCreateResponse) => {
          setAddOrganisationDlgOpen(false);
          handleAddStoreToOrganisationAccounts(
            selectedOrganisation.accounts.map((el) => el.id)
          );

          // this is very bad, but since these batch dispatch actions in redux are not async
          // we can't await them which will lead to race condition so we give them a 1s headstart
          setTimeout(() => {
            handleUpdate();
          }, 1000);

          toast(
            `Store added '${selectedOrganisation?.name}' with id '${createResponse.id}'`
          );
        })
        .catch((err: APIError) => {
          toast(
            `Unable to remove store from organisation with name '${selectedOrganisation?.name}': ${err.error}`
          );
        });
    }
  };

  const handleApproveAccount = () => {
    const selectedAccounts = accounts.filter(
      (account) => account.email === selectedAccountMail
    );
    if (selectedAccounts.length == 0 || selectedStore == undefined) {
      toast(
        `No account or store has been selected! Unable to approve account!`
      );
      setAddUserDlgOpen(false);
      return;
    }
    const selectedAccount = selectedAccounts[0];
    dispatch(
      createApproval({
        acid: selectedAccount.acid,
        scope: "kytron::read_events",
        rsid: selectedStore?.csid,
        rstype: 0,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: ApprovalCreateResponse) => {
        setAddUserDlgOpen(false);
        toast(
          `Account with email '${selectedAccountMail}' was approved to read from store '${selectedStore?.name}' (${createResponse.asi})`
        );
        dispatch(listApprovals({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        toast(
          `Unable to approve account with email '${selectedAccountMail}': ${err.error}`
        );
      });
  };

  const handleAddSiteref = () => {
    setAddSiterefDlgOpen(true);
  };

  const handleAddSiterefClose = () => {
    setAddSiterefDlgOpen(false);
  };

  const updateProviderInfo = (
    adsProvider: string,
    landingPageURL: string,
    useRedirectGateway: boolean
  ) => {
    setWebsiteURL(
      getWebsiteURL(landingPageURL, useRedirectGateway, backendUrl)
    );
    setURLParameters(
      getURLParams(
        adsProvider,
        landingPageURL,
        selectedStore?.csid ?? "",
        useRedirectGateway
      )
    );
  };

  const revokeApproval = (acid: string) => {
    // console.log(`Revoking approvals for user ${acid}`);
    const userApprovals = relevantApprovals
      .filter((approval) => approval.acid === acid)
      .map((approval) => approval.asi);
    // approvals = approvals.filter((approval) => !userApprovals.includes(approval.asi));
    // console.log(`Revoking approvals ${JSON.stringify(userApprovals)}`);
    dispatch(
      deleteApproval({
        asis: userApprovals,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        toast(
          `Approvals for user with id '${acid}' have been revoked for store '${selectedStore?.name}'`
        );
        dispatch(listApprovals({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        toast(
          `Unable to revoke approval for user with id '${acid}': ${err.error}`
        );
      });
  };

  const unlinkAccounts = (csrids: Array<string>) => {
    dispatch(
      deleteSiteref({
        csrids,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        toast(
          `Reference(s) '${csrids}' has/have been unlinked from '${selectedStore?.name}'`
        );
        dispatch(listSiterefs({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        toast(`Unable to unlink reference(s) '${csrids}': ${err.error}`);
      });
  };

  const handleCreateSiteref = () => {
    if (selectedStore == undefined) {
      toast(`No store has been selected! Unable to link account!`);
      setAddSiterefDlgOpen(false);
      return;
    }
    dispatch(
      createSiteref({
        csid: selectedStore.csid,
        refid: refid,
        reftype: reftype,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then((createResponse: SiterefCreateResponse) => {
        setAddSiterefDlgOpen(false);
        toast(
          `Account with ID '${refid}' was linked with store '${selectedStore?.name}' (${createResponse.csrid})`
        );
        dispatch(listSiterefs({ session, backendUrl }));
      })
      .catch((err: APIError) => {
        toast(`Unable to link account with id '${refid}': ${err.error}`);
      });
  };

  const handleDeleteStore = () => {
    toast("");
    toast("");
    setDeleteStoreDlgOpen(true);
  };

  const handleDeleteStoreClose = () => {
    setDeleteStoreDlgOpen(false);
  };

  const handleDeleteStoreAction = () => {
    if (!selectedStore) return;

    dispatch(
      deleteStore({
        csid: selectedStore.csid,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        handleUpdate();
        navigate(`/stores`);
      })
      .catch((err: APIError) => {
        toast(`Unable to delete store '${selectedStore.name}': ${err.error}`);
      });
  };

  const handleCreateServerToken = () => {
    if (!selectedStore) return;
    if (!ssTokenUser) return;

    dispatch(
      createStoreServerToken({
        csid: selectedStore?.csid,
        id: ssTokenUser,
        session,
        backendUrl: chiefBackendUrl,
      })
    )
      .unwrap()
      .then((createResponse: StoreServerTokenCreateResponse) => {
        setSsToken(createResponse.token);
        toast(
          `Server side token for store '${selectedStore?.description}' was successfully created: ${createResponse.token}`
        );
      })
      .catch((err: APIError) => {
        toast(
          `Unable to create server side token for store '${selectedStore.description}': ${err.error}`
        );
      });
  };

  const handleOpenTokenModal = () => {
    setTokenModalOpen(true);
    setSsToken("");
    setSsTokenUser("");
  };

  const handleCloseTokenModal = () => {
    setTokenModalOpen(false);
    setSsToken("");
    setSsTokenUser("");
  };

  const handleOpenIntegrationModal = () => {
    setIntegrationModalOpen(true);
    //  setIntegration('');
  };

  useEffect(() => {
    const system = shopSystemOptions.find(
      (el) => el.value === selectedStore?.shop_system
    );
    if (
      (!shopSystem?.value ||
        shopSystem?.value !== selectedStore?.shop_system) &&
      system
    )
      setShopSystem(system);
  }, [selectedStore?.shop_system]);

  const onIntegrationUpdate = async (id: string) => {
    if (!selectedStore) return;
    if (!id) return;
    toast("");
    toast("");
    return dispatch(
      getShopifyIntegration({
        id,
        session,
        backendUrl: shopconnectorBackendUrl,
      })
    )
      .unwrap()
      .then((integration: ShopifyIntegration) => {
        if (!integration) return;
        setIntegrationToUpdate(id);
        setDomain(integration.domain);
        setAPIKey(integration.api_key);
        setAccessToken(integration.access_token);
        setAPISecret(integration.api_secret);
        setFingerprinting(integration.fingerprinting_active);
        setBetaFeatures(integration.beta_active);
        setCookie(
          integration.cookie_active_without_consent
            ? "cookie_active_without_consent"
            : integration.cookie_active_with_consent
            ? "cookie_active_with_consent"
            : "disabled"
        );
        setSnippet(integration.snippet_content ?? "");
        handleOpenIntegrationModal();
      })
      .catch((err: APIError) => {
        toast(
          `Unable to get shopify integration for integration ID '${id}': ${err.error}`
        );
      });
  };

  const handleCloseIntegrationModal = () => {
    setIntegrationModalOpen(false);
    setIntegrationToUpdate("");
    toast("");
    toast("");
    setDomain("");
    setAPIKey("");
    setAccessToken("");
    setAPISecret("");
    setFingerprinting(false);
    setBetaFeatures(false);
    setShowPassword(false);
    setCookie("disabled");
    setSnippet("");
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleCreateShopifyIntegration = () => {
    if (!selectedStore) return;
    if (!accessToken || !APIKey || !APISecret || !domain) {
      return toast("Please fill out all fields");
    }
    toast("");
    toast("");
    dispatch(
      createShopifyIntegration({
        csid: selectedStore?.csid,
        access_token: accessToken,
        api_key: APIKey,
        api_secret: APISecret,
        beta_active: betaFeatures,
        cookie_active_without_consent:
          cookie === "cookie_active_without_consent",
        cookie_active_with_consent: cookie === "cookie_active_with_consent",
        fingerprinting_active: fingerprinting,
        domain: domain,
        session,
        backendUrl: shopconnectorBackendUrl,
      })
    )
      .unwrap()
      .then((integration) => {
        toast(
          `Shopify integration for domain '${domain}' was successfully created`
        );
        handleUpdate();
        setIntegrationToUpdate(integration.id);
        setSnippet(integration.snippet_content ?? "");
      })
      .catch((err: APIError) => {
        toast(
          `Unable to create integration for store '${selectedStore.description}': ${err.error}`
        );
      });
  };
  const handleUpdateShopifyIntegration = () => {
    if (!selectedStore) return;
    if (!integrationToUpdate) return;
    if (!accessToken || !APIKey || !APISecret || !domain) {
      return toast("Please fill out all fields");
    }
    toast("");
    toast("");
    dispatch(
      updateShopifyIntegration({
        id: integrationToUpdate,
        csid: selectedStore?.csid,
        access_token: accessToken,
        api_key: APIKey,
        api_secret: APISecret,
        beta_active: betaFeatures,
        cookie_active_without_consent:
          cookie === "cookie_active_without_consent",
        cookie_active_with_consent: cookie === "cookie_active_with_consent",
        fingerprinting_active: fingerprinting,
        domain: domain,
        session,
        backendUrl: shopconnectorBackendUrl,
      })
    )
      .unwrap()
      .then((integration) => {
        toast(
          `Shopify integration for domain '${domain}' was successfully updated`
        );
        setSnippet(integration.snippet_content ?? "");
        handleUpdate();
      })
      .catch((err: APIError) => {
        toast(
          `Unable to update integration for store '${selectedStore.description}': ${err.error}`
        );
      });
  };
  const handleDeleteShopifyIntegration = (id: string) => {
    if (!selectedStore) return;
    toast("");
    toast("");
    dispatch(
      deleteShopifyIntegration({
        id,
        session,
        backendUrl: shopconnectorBackendUrl,
      })
    )
      .unwrap()
      .then(() => {
        toast(
          `Shopify integration for store '${selectedStore?.description}' was successfully deleted`
        );
        handleUpdate();
      })
      .catch((err: APIError) => {
        toast(
          `Unable to delete Shopify integration for store '${selectedStore.description}': ${err.error}`
        );
      });
  };

  const handleUpdateStore = () => {
    // until now, this function is only used to update the shopSystem
    if (!selectedStore) return;
    if (!shopSystem?.value) {
      toast.error(
        "No Shop System selected. Please fill out all fields before updating."
      );
      return;
    }
    dispatch(
      updateStore({
        csid: selectedStore.csid,
        name: storeName ?? selectedStore.name,
        url: storeUrl ?? selectedStore.url,
        shop_system: shopSystem?.value ?? "",
        description: storeDescription ?? selectedStore.description,
        pg_idx: selectedStore?.pg_idx,
        redis_idx: selectedStore?.redis_idx,
        active: isStoreActive,
        session,
        backendUrl,
      })
    )
      .unwrap()
      .then(() => {
        toast.success(
          `Update for store '${selectedStore.description}' was successful`
        );

        handleUpdate();
      })
      .catch((err: APIError) => {
        toast.error(
          `Unable to update shop system for store '${selectedStore.description}'`
        );
        console.error(
          `Unable to update shop system for store '${selectedStore.description}': ${err.error}`
        );
      })
      .finally(() => {
        setEditDlgOpen(false);
      });
  };

  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between">
            <div className="space-y-1">
              <CardTitle className="flex items-center gap-2">
                {selectedStore?.name} - {selectedStore?.description}
                <span>
                  cluster{" "}
                  {selectedStore?.pg_idx !== undefined
                    ? selectedStore.pg_idx + 1
                    : ""}
                </span>
                {selectedStore?.active ? (
                  <Badge variant="emerald">Active</Badge>
                ) : (
                  <Badge variant="rose">Inactive</Badge>
                )}
              </CardTitle>
            </div>
            <Button onClick={handleClickOpen}>
              <PencilIcon className="mr-2 h-4 w-4" />
              Edit Store
            </Button>
          </div>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div className="flex items-center gap-2">
              <div className="flex-1">
                <Label>URL</Label>
                <div className="flex items-center gap-2">
                  <Input value={selectedStore?.url} readOnly />
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      navigator.clipboard.writeText(selectedStore?.url ?? "");
                      toast.success("URL copied to clipboard");
                    }}
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <div className="flex-1">
                <Label>Store ID</Label>
                <div className="flex items-center gap-2">
                  <Input value={selectedStore?.csid} readOnly />
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => {
                      navigator.clipboard.writeText(selectedStore?.csid ?? "");
                      toast.success("Store ID copied to clipboard");
                    }}
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </div>

            {updateStoreApproval && (
              <div className="flex items-center gap-4">
                <Select
                  value={shopSystem?.value}
                  onValueChange={(value) => {
                    const system = shopSystemOptions.find(
                      (opt) => opt.value === value
                    );
                    setShopSystem(system);
                  }}
                >
                  <SelectTrigger className="w-[300px]">
                    <SelectValue placeholder="Select shop system" />
                  </SelectTrigger>
                  <SelectContent>
                    {shopSystemOptions.map((option) => (
                      <SelectItem key={option.value} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                {shopSystem?.value &&
                  shopSystem?.value !== selectedStore?.shop_system && (
                    <Button onClick={handleUpdateStore}>Save</Button>
                  )}
              </div>
            )}

            {manageOrgasApproval && (
              <div>
                <Button variant="secondary" onClick={handleOpenTokenModal}>
                  Create server-side token
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
      {/* <Card>
        <CardHeader>
          <CardTitle>Tracking Code</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <RadioGroup value={shopType} onValueChange={handleShopTypeChange}>
              <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="shopify" id="shopify" />
                  <Label htmlFor="shopify">Shopify</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="woocommerce" id="woocommerce" />
                  <Label htmlFor="woocommerce">WooCommerce</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="shopware5" id="shopware5" />
                  <Label htmlFor="shopware5">Shopware 5</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="shopware6" id="shopware6" />
                  <Label htmlFor="shopware6">Shopware 6</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="magento2_4" id="magento2_4" />
                  <Label htmlFor="magento2_4">Magento v2.4</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="digistore24" id="digistore24" />
                  <Label htmlFor="digistore24">DigiStore24</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="elopage" id="elopage" />
                  <Label htmlFor="elopage">Elopage</Label>
                </div>
                <div className="flex items-center space-x-2">
                  <RadioGroupItem value="custom" id="custom" />
                  <Label htmlFor="custom">Custom</Label>
                </div>
              </div>
            </RadioGroup>
            <Separator />
            <div className="flex items-center gap-4">
              <div className="flex items-center space-x-2">
                <Switch checked={enableFP} onCheckedChange={handleChangeFP} />
                <Label>Enable Fingerprinting</Label>
              </div>

              <div className="flex items-center space-x-2">
                <RadioGroup
                  value={trackingChannel}
                  onValueChange={(val) => handleTrackingChannelChange(val)}
                  className="flex"
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="production" id="production" />
                    <Label htmlFor="production">Production</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="beta" id="beta" />
                    <Label htmlFor="beta">Beta</Label>
                  </div>
                </RadioGroup>
              </div>
            </div>
            <div className="space-y-6">
              <div>
                <h4 className="text-sm font-medium mb-2">Main tracking code</h4>
                {trackingCode ? (
                  <div className="flex items-center gap-2">
                    <div className="w-[96%]">
                      <CodeBlock
                        text={trackingCode}
                        language="html"
                        theme={nord}
                      />
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        navigator.clipboard.writeText(trackingCode);
                        toast.success("Tracking code copied to clipboard");
                      }}
                    >
                      <Copy className="h-4 w-4" />
                    </Button>
                  </div>
                ) : (
                  <span className="italic text-foreground">not provided</span>
                )}
              </div>

              <div>
                <h4 className="text-sm font-medium mb-2">Checkout code</h4>
                {checkoutCode ? (
                  <div className="flex items-center gap-2">
                    <div className="w-[96%]">
                      <CodeBlock
                        text={checkoutCode}
                        language="html"
                        theme={nord}
                      />
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        navigator.clipboard.writeText(checkoutCode);
                        toast.success("Checkout code copied to clipboard");
                      }}
                    >
                      <Copy className="h-4 w-4" />
                    </Button>
                  </div>
                ) : (
                  <span className="italic text-foreground">not provided</span>
                )}
              </div>

              <div>
                <h4 className="text-sm font-medium mb-2">Post-purchase code</h4>
                {ppsCode ? (
                  <div className="flex items-center gap-2">
                    <div className="w-[96%]">
                      <CodeBlock text={ppsCode} language="html" theme={nord} />
                    </div>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        navigator.clipboard.writeText(ppsCode);
                        toast.success("Post-purchase code copied to clipboard");
                      }}
                    >
                      <Copy className="h-4 w-4" />
                    </Button>
                  </div>
                ) : (
                  <span className="italic text-foreground">not provided</span>
                )}
              </div>
            </div>
          </div>
        </CardContent>
      </Card> */}

      <Card>
        <CardHeader>
          <CardTitle>Organisation</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Organisation ID</TableHead>
                  <TableHead>Name</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {relevantOrganisation ? (
                  <TableRow key={relevantOrganisation.id}>
                    <LinkedTableCell
                      href={`/organisations/${relevantOrganisation.id}`}
                      value={relevantOrganisation.id}
                      onCopy={(value) =>
                        toast("Organisation ID copied to clipboard")
                      }
                    />
                    <LinkedTableCell
                      href={`/organisations/${relevantOrganisation.id}`}
                      value={relevantOrganisation.name}
                    />
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} className="text-center">
                      No organisation found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>

            {manageOrgasApproval && (
              <Button onClick={handleAddOrganisation}>
                <PlusCircle className="mr-2 h-4 w-4" />
                Manage Organisation
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Approved Users</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div className="flex items-center border rounded-md px-3">
              <Search className="h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search users..."
                value={searchUser}
                onChange={(e) => setSearchUser(e.target.value)}
                className="border-0 focus-visible:ring-0"
              />
            </div>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Account ID</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead className="w-[100px]">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {approvedUsers?.map((user) => (
                  <TableRow key={user.acid}>
                    <LinkedTableCell
                      href={`/accounts/${user.acid}`}
                      value={user.acid}
                      onCopy={(value) =>
                        toast("Account ID copied to clipboard")
                      }
                    />
                    <LinkedTableCell
                      href={`/accounts/${user.acid}`}
                      value={user.email}
                      onCopy={(value) => toast("Email copied to clipboard")}
                    />
                    <TableCell>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={(e) => {
                                e.stopPropagation();
                                user.acid && revokeApproval(user.acid);
                              }}
                            >
                              <UserMinus className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            Revoke access for {user.email}
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <Button onClick={handleAddUser}>
              <PlusCircle className="mr-2 h-4 w-4" />
              Add User
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Linked Ad Accounts</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Reference ID</TableHead>
                  <TableHead>Reference Type</TableHead>
                  <TableHead>Last Scrape</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Tracking</TableHead>
                  <TableHead className="w-[100px]">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {relevantSiterefs?.map((sref) => {
                  const utmStatus = getUtmStatus(sref.refid);

                  return (
                    <TableRow key={sref.refid}>
                      <LinkedTableCell
                        value={sref.refid}
                        onCopy={(value) =>
                          toast("Reference ID copied to clipboard")
                        }
                      />
                      <LinkedTableCell value={getPrettyRefType(sref.reftype)} />
                      <TableCell>
                        {sref.last_scrape_date
                          ? DateTime.fromISO(sref.last_scrape_date).toFormat(
                              "yyyy-MM-dd"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {!sref.last_scrape_date ? (
                          <div className="flex items-center gap-2 text-destructive">
                            <AlertCircle className="h-4 w-4" />
                            <span className="text-sm">
                              No adconnector data found
                            </span>
                          </div>
                        ) : dayjs(sref.last_scrape_date).isBefore(
                            dayjs().subtract(2, "day")
                          ) ? (
                          <div className="flex items-center gap-2 text-destructive">
                            <AlertCircle className="h-4 w-4" />
                            <span className="text-sm">
                              Last scrape was{" "}
                              {Math.round(
                                DateTime.fromISO(sref.last_scrape_date).diffNow(
                                  "days"
                                ).days * -1
                              )}{" "}
                              day(s) ago
                            </span>
                          </div>
                        ) : (
                          <CheckCircle className="h-4 w-4 text-emerald-500" />
                        )}
                      </TableCell>
                      <TableCell>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              {loadingUtmAccounts.has(sref.refid) ? (
                                <span className="h-4 w-4 block rounded-full border-2 border-t-transparent border-slate-500 animate-spin"></span>
                              ) : utmStatus?.hasIssues ? (
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="cursor-pointer !px-0"
                                  onClick={() =>
                                    navigateToUtmDashboard(
                                      sref.refid,
                                      sref.source
                                    )
                                  }
                                >
                                  <CircleX className="h-4 w-4 text-destructive" />
                                </Button>
                              ) : utmStatus ? (
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="!px-0"
                                  onClick={() =>
                                    navigateToUtmDashboard(
                                      sref.refid,
                                      sref.source
                                    )
                                  }
                                >
                                  <CheckCircle className="h-4 w-4 text-emerald-500" />
                                </Button>
                              ) : (
                                <span className="text-muted-foreground text-sm">
                                  -
                                </span>
                              )}
                            </TooltipTrigger>
                            <TooltipContent>
                              {loadingUtmAccounts.has(sref.refid)
                                ? "Loading UTM data..."
                                : utmStatus?.hasIssues
                                ? `UTM issues detected: ${
                                    utmStatus.issueCount
                                  } ${
                                    utmStatus.issueCount === 1
                                      ? "issue"
                                      : "issues"
                                  }`
                                : utmStatus
                                ? "UTMs configured correctly"
                                : "No UTM data available"}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </TableCell>
                      <TableCell>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => unlinkAccounts([sref.csrid])}
                              >
                                <Unlink className="h-4 w-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              Unlink account {sref.refid}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            <Button onClick={handleAddSiteref}>
              <PlusCircle className="mr-2 h-4 w-4" />
              Link Account
            </Button>
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>Integrations</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Integration ID</TableHead>
                  <TableHead>Name</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead className="w-[100px]">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {relevantIntegrations?.map((integration) => (
                  <TableRow key={integration.id}>
                    <LinkedTableCell
                      value={integration.id}
                      onCopy={(value) =>
                        toast("Integration ID copied to clipboard")
                      }
                    />
                    <LinkedTableCell
                      value={integration.name}
                      onCopy={(value) =>
                        toast("Integration name copied to clipboard")
                      }
                    />
                    <LinkedTableCell value={integration.type} />
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() =>
                                  onIntegrationUpdate(integration.id)
                                }
                              >
                                <PencilIcon className="h-4 w-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              Edit integration for {integration.name}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() =>
                                  handleDeleteShopifyIntegration(integration.id)
                                }
                              >
                                <CircleX className="h-4 w-4" />
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              Remove integration for {integration.name}
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Button onClick={handleOpenIntegrationModal}>
              <PlusCircle className="mr-2 h-4 w-4" />
              Create Integration
            </Button>
          </div>

          <Dialog
            open={integrationModalOpen}
            onOpenChange={setIntegrationModalOpen}
          >
            <DialogContent className="sm:max-w-[600px]">
              <DialogHeader>
                <DialogTitle>
                  {integrationToUpdate ? "Update" : "Create"} an integration
                </DialogTitle>
              </DialogHeader>
              <div className="space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="domain">Domain</Label>
                  <Input
                    id="domain"
                    value={domain}
                    onChange={(e) => setDomain(e.target.value)}
                    placeholder="example.myshopify.com"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="access_token">Access Token</Label>
                  <div className="relative">
                    <Input
                      id="access_token"
                      type={showPassword ? "text" : "password"}
                      value={accessToken}
                      onChange={(e) => setAccessToken(e.target.value)}
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="absolute right-2 top-1/2 -translate-y-1/2"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </Button>
                  </div>
                </div>

                <div className="space-y-2">
                  <Label htmlFor="api_key">API Key</Label>
                  <div className="relative">
                    <Input
                      id="api_key"
                      type={showPassword ? "text" : "password"}
                      value={APIKey}
                      onChange={(e) => setAPIKey(e.target.value)}
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="absolute right-2 top-1/2 -translate-y-1/2"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </Button>
                  </div>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="api_secret">API Secret</Label>
                  <div className="relative">
                    <Input
                      id="api_secret"
                      type={showPassword ? "text" : "password"}
                      value={APISecret}
                      onChange={(e) => setAPISecret(e.target.value)}
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="icon"
                      className="absolute right-2 top-1/2 -translate-y-1/2"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <EyeOff className="h-4 w-4" />
                      ) : (
                        <Eye className="h-4 w-4" />
                      )}
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="fingerprinting"
                      checked={fingerprinting}
                      onCheckedChange={(checked) =>
                        setFingerprinting(checked as boolean)
                      }
                    />
                    <Label htmlFor="fingerprinting">
                      Enable fingerprinting
                    </Label>
                  </div>

                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="beta"
                      checked={betaFeatures}
                      onCheckedChange={(checked) =>
                        setBetaFeatures(checked as boolean)
                      }
                    />
                    <Label htmlFor="beta">Enable latest beta features</Label>
                  </div>

                  <div className="space-y-2">
                    <Label>Cookies</Label>
                    <RadioGroup
                      value={cookie}
                      onValueChange={(value) => setCookie(value)}
                    >
                      <div className="space-y-2">
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="disabled" id="disabled" />
                          <Label htmlFor="disabled">
                            Disable Cookie Tracking
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="cookie_active_with_consent"
                            id="cookie_with_consent"
                          />
                          <Label htmlFor="cookie_with_consent">
                            Enable cookie tracking (EU shop)
                          </Label>
                        </div>
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem
                            value="cookie_active_without_consent"
                            id="cookie_without_consent"
                          />
                          <Label htmlFor="cookie_without_consent">
                            Enable cookie tracking (Non EU shop)
                          </Label>
                        </div>
                      </div>
                    </RadioGroup>
                  </div>

                  {snippet && cookie === "cookie_active_with_consent" && (
                    <div className="space-y-2">
                      <Label>Code Snippet</Label>
                      <div className="p-4 bg-muted rounded-md font-mono text-sm whitespace-pre-wrap">
                        {snippet}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <DialogFooter>
                <Button variant="outline" onClick={handleCloseIntegrationModal}>
                  Cancel
                </Button>
                <Button
                  onClick={
                    integrationToUpdate
                      ? handleUpdateShopifyIntegration
                      : handleCreateShopifyIntegration
                  }
                  disabled={!domain || !accessToken || !APIKey || !APISecret}
                >
                  {integrationToUpdate ? "Update" : "Create"}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
      <Dialog open={editDlgOpen} onOpenChange={setEditDlgOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Edit Store</DialogTitle>
            <DialogDescription>
              Please provide the corresponding store info here:
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4 py-4">
            <div className="space-y-2">
              <Label htmlFor="name">Company name</Label>
              <Input
                id="name"
                required
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="url">URL</Label>
              <Input
                id="url"
                required
                value={storeUrl}
                onChange={(e) => setStoreUrl(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="description">Store name</Label>
              <Input
                id="description"
                required
                value={storeDescription}
                onChange={(e) => setStoreDescription(e.target.value)}
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="shop-system">Shop System</Label>
              <Select
                value={shopSystem?.value}
                onValueChange={(value) => {
                  const system = shopSystemOptions.find(
                    (opt) => opt.value === value
                  );
                  setShopSystem(system);
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select shop system" />
                </SelectTrigger>
                <SelectContent>
                  {shopSystemOptions.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="flex items-center space-x-2">
              <Label htmlFor="active">Store Status</Label>
              <Switch
                id="active"
                checked={isStoreActive}
                onCheckedChange={setIsStoreActive}
              />
              <span className="text-sm text-muted-foreground">Active</span>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button onClick={handleUpdateStore} disabled={storeName.length < 4}>
              Update
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={tokenModalOpen} onOpenChange={setTokenModalOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>
              Create server-side token for {selectedStore?.description}
            </DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label>User ID</Label>
              <Input
                value={ssTokenUser}
                onChange={(e) => setSsTokenUser(e.target.value.trim())}
                placeholder="Enter user ID"
              />
            </div>

            <div className="space-y-2">
              <Alert variant="blue">
                After creating the token it will be displayed below. Please make
                sure to immediately copy or write it down somewhere, because you
                won't be able to see it after closing this modal.
              </Alert>
              {ssToken && (
                <div className="space-y-2">
                  <Label>Created server-side token:</Label>
                  <div className="p-4 bg-muted rounded-md font-mono break-all">
                    {ssToken}
                  </div>
                </div>
              )}
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={handleCloseTokenModal}>
              Cancel
            </Button>
            <Button onClick={handleCreateServerToken} disabled={!ssTokenUser}>
              Create Token
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog
        open={addOrganisationDlgOpen}
        onOpenChange={setAddOrganisationDlgOpen}
      >
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add organisation to store</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <ComboboxMultiSelect
              selectedItems={
                selectedOrganisation
                  ? [
                      {
                        id: selectedOrganisation.id,
                        name: selectedOrganisation.name,
                      },
                    ]
                  : []
              }
              items={
                organisations?.map((org) => ({
                  id: org.id,
                  name: org.name,
                })) || []
              }
              onItemSelect={(item) => {
                const org = organisations?.find((o) => o.id === item.id);
                setSelectedOrganisation(org || null);
              }}
              onItemRemove={() => setSelectedOrganisation(null)}
              placeholder="Select an organisation"
              emptyStateText="No organisations found"
              searchInputPlaceholder="Search organisations..."
              badgeVariant="blue"
            />
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={handleAddOrganisationClose}>
              Cancel
            </Button>
            <Button
              onClick={handleAddOrganisationToStore}
              disabled={!selectedOrganisation}
            >
              Update
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={addUserDlgOpen} onOpenChange={setAddUserDlgOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Approve user account</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <ComboboxMultiSelect
              selectedItems={
                selectedAccountMail
                  ? [
                      {
                        id: selectedAccountMail,
                        name: selectedAccountMail,
                      },
                    ]
                  : []
              }
              items={relevantUsers.map((user) => ({
                id: user.email || "",
                name: user.email || "",
              }))}
              onItemSelect={(item) => setSelectedAccountMail(item.id)}
              onItemRemove={() => setSelectedAccountMail("")}
              placeholder="Select a user"
              emptyStateText="No users found"
              searchInputPlaceholder="Search users..."
              badgeVariant="amber"
            />
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={handleAddUserClose}>
              Cancel
            </Button>
            <Button
              onClick={handleApproveAccount}
              disabled={!selectedAccountMail}
            >
              Approve
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={deleteStoreDlgOpen} onOpenChange={setDeleteStoreDlgOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete store {selectedStore?.description}</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this store? This action cannot be
              undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={handleDeleteStoreClose}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={handleDeleteStoreAction}>
              Delete Store
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={addSiterefDlgOpen} onOpenChange={setAddSiterefDlgOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Link account</DialogTitle>
            <DialogDescription>
              Please provide info for account to be linked here:
            </DialogDescription>
          </DialogHeader>
          <div className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="refid">Account ID</Label>
              <Input
                id="refid"
                value={refid}
                onChange={(e) => setRefid(e.target.value)}
                placeholder="Enter account ID"
              />
            </div>

            <div className="space-y-2">
              <Label>Account Type</Label>
              <RadioGroup value={reftype} onValueChange={setReftype}>
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="google" id="google" />
                    <Label htmlFor="google">Google Account ID</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="klayvio" id="klayvio" />
                    <Label htmlFor="klayvio">Klayvio Account ID</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="tiktok" id="tiktok" disabled />
                    <Label htmlFor="tiktok" className="text-muted-foreground">
                      Tiktok Account ID
                    </Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="pinterest" id="pinterest" />
                    <Label htmlFor="pinterest">Pinterest Account ID</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="fb" id="fb" disabled />
                    <Label htmlFor="fb" className="text-muted-foreground">
                      Facebook Account ID
                    </Label>
                  </div>
                </div>
              </RadioGroup>
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={handleAddSiterefClose}>
              Cancel
            </Button>
            <Button
              onClick={handleCreateSiteref}
              disabled={
                refid.length < 4 ||
                relevantSiterefs?.some((x) => x.refid === refid)
              }
            >
              Link
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {deleteStoreApproval && (
        <div className="space-y-4 border-t border-destructive/20 pt-6 mt-6">
          <h3 className="text-lg font-semibold text-destructive">
            Danger Zone
          </h3>
          <div className="space-y-4">
            <Button
              variant="destructive"
              onClick={handleDeleteStore}
              disabled={!selectedStore}
            >
              Delete Store
            </Button>
            {!selectedStore && (
              <p className="text-sm text-muted-foreground">
                You must delete or remove all stores for this organisation
                before deleting
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientStore;
