import React from "react";
import { TableCell } from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const ContentTableCell: React.FC<{ content: string }> = ({ content }) => {
  const displayContent = content || "-";
  const needsTooltip = content && content.length > 50;

  return (
    <TableCell className="truncate max-w-[400px]">
      {needsTooltip ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="cursor-help">{displayContent}</span>
            </TooltipTrigger>
            <TooltipContent className="max-w-md">
              <p className="break-words">{content}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        displayContent
      )}
    </TableCell>
  );
};

export default ContentTableCell;
