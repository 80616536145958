import React from "react";
import { Link } from "react-router-dom";
import { Copy } from "lucide-react";
import { TableCell } from "../ui/table";

interface LinkedTableCellProps {
  href?: string;
  value: React.ReactNode;
  copyValue?: string;
  label?: string;
  className?: string;
  onCopy?: (value: string) => void;
  stopPropagation?: boolean;
}

const LinkedTableCell = ({
  href,
  value,
  copyValue,
  label = "Value",
  className = "",
  onCopy,
  stopPropagation = false,
}: LinkedTableCellProps) => {
  const handleCopy = (e: React.MouseEvent) => {
    e.preventDefault();
    if (stopPropagation) e.stopPropagation();
    const textToCopy = copyValue ?? (typeof value === "string" ? value : "");
    navigator.clipboard.writeText(textToCopy);
    onCopy?.(textToCopy);
  };

  const content = (
    <div className="flex gap-2 items-center">
      <span className="flex gap-1">{value}</span>
      {onCopy && (
        <button
          className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-slate-100 dark:hover:bg-slate-800 rounded"
          onClick={handleCopy}
        >
          <Copy className="h-4 w-4" />
        </button>
      )}
    </div>
  );

  return (
    <TableCell className={`group ${className}`}>
      {href ? (
        <Link
          to={href}
          className="no-underline text-inherit"
          onClick={(e) => stopPropagation && e.stopPropagation()}
        >
          {content}
        </Link>
      ) : (
        content
      )}
    </TableCell>
  );
};

export default LinkedTableCell;
