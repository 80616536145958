import React from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui/popover";
import { Button } from "@components/ui/button";
import { PlusCircle, Trash2 } from "lucide-react";
import { Separator } from "./separator";
import { Badge } from "@components/ui/badge";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@components/ui/command";
import { Checkbox } from "./checkbox";

export type DataFilterOption = { label: string; value: string };

interface DataFilterProps {
  title?: string;
  value: string[];
  onValueChange: (value: string[]) => void;
  options: DataFilterOption[];
  withInput?: boolean;
}

export function DataFilter({
  title,
  value,
  onValueChange,
  options,
  withInput = true,
}: DataFilterProps) {
  const selected = new Set(value);

  const handleClearFilters = (e: React.MouseEvent) => {
    e.stopPropagation();
    onValueChange([]);
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="outline" className="border-dashed">
          <PlusCircle className="h-4 w-4 mr-1" /> {title}
          {selected.size > 0 && (
            <>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <div className="flex items-center gap-1">
                <Badge
                  variant="default"
                  className="rounded-sm px-1 font-normal lg:hidden"
                >
                  {selected.size}
                </Badge>
                <div className="hidden items-center space-x-1 lg:flex">
                  {selected.size > 1 ? (
                    <Badge
                      variant="purple"
                      className="text-xs rounded-sm px-1 font-normal"
                    >
                      {selected.size} selected
                    </Badge>
                  ) : (
                    options
                      .filter((option) => selected.has(option.value))
                      .map((option) => (
                        <Badge
                          variant="purple"
                          key={option.value}
                          className="text-xs rounded-sm px-1 font-normal"
                        >
                          {option.label}
                        </Badge>
                      ))
                  )}
                </div>
                <button
                  onClick={handleClearFilters}
                  className="ml-1 p-1 hover:bg-muted rounded-sm"
                  title="Clear filters"
                >
                  <Trash2 className="h-3 w-3 text-muted-foreground hover:text-destructive" />
                </button>
              </div>
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          {withInput && <CommandInput placeholder={title} />}
          <CommandList className="max-h-[300px] overflow-y-auto">
            <CommandEmpty>No results found.</CommandEmpty>
            {selected.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => onValueChange([])}
                    className="justify-center text-center"
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
            <CommandGroup>
              {options.map((option) => {
                const isSelected = selected.has(option.value);
                return (
                  <CommandItem
                    key={option.value}
                    onSelect={() => {
                      const newSelected = new Set(selected);
                      if (isSelected) {
                        newSelected.delete(option.value);
                      } else {
                        newSelected.add(option.value);
                      }
                      onValueChange(Array.from(newSelected));
                    }}
                  >
                    <Checkbox checked={isSelected} className="mr-2" />
                    <span className="text-xs">{option.label}</span>
                  </CommandItem>
                );
              })}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
