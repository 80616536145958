import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Switch } from "@/components/ui/switch";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import ComboboxMultiSelect from "./components/ComboboxMultiSelectProps/ComboboxMultiSelectProps";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch as useDispatch } from "./store";
import { listStores } from "./reducers/stores";

type ManagerTypeValue = "meta" | "google" | "tiktok" | "pinterest" | "outbrain";
type EnvironmentTypeValue = "production" | "beta";

interface Store {
  csid: string;
  url: string;
}

interface Alert {
  id: string;
  type: string;
  message: string;
  details: string;
  severity: string;
  managers: ManagerTypeValue[];
  csids: string[];
  environment: EnvironmentTypeValue[];
  startTime: string;
  endTime: string;
}

const AlertCreator = () => {
  const dispatch = useDispatch();
  const session = useSelector((state: RootState) => state.user.session);
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const scope = "kytron::read_events";
  const stores = useSelector((state: RootState) => state.stores.stores);

  useEffect(() => {
    if (stores.length === 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
  }, []);

  const [alert, setAlert] = useState<Alert>({
    id: generateUniqueId(),
    type: "info",
    message: "",
    details: "",
    severity: "medium",
    managers: [],
    csids: [],
    environment: ["production"],
    startTime: new Date().toISOString(),
    endTime: "",
  });

  function generateUniqueId() {
    const prefix = "alert";
    const timestamp = Date.now().toString(36); // Convert timestamp to base36
    const randomStr = Math.random().toString(36).substring(2, 8); // Random string
    return `${prefix}-${timestamp}-${randomStr}`;
  }

  const [markdown, setMarkdown] = useState("");
  const [copied, setCopied] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [fileName, setFileName] = useState("");
  const [alertFileName, setAlertFileName] = useState("");
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);

  const validTypes = ["info", "warning", "error", "success"];
  const validSeverity = ["low", "medium", "high", "critical"];
  const validManagers = ["meta", "google", "tiktok", "pinterest", "outbrain"];
  const validEnvironments = ["production", "beta"];

  const validateAlert = (): string[] => {
    const errors: string[] = [];

    if (!alert.message) errors.push("Missing required field: message");

    if (alert.type && !validTypes.includes(alert.type)) {
      errors.push(
        `Invalid type: ${alert.type}. Must be one of: ${validTypes.join(", ")}`
      );
    }

    if (alert.severity && !validSeverity.includes(alert.severity)) {
      errors.push(
        `Invalid severity: ${
          alert.severity
        }. Must be one of: ${validSeverity.join(", ")}`
      );
    }

    if (!alert.environment || alert.environment.length === 0) {
      errors.push("Missing required field: environment");
    }

    return errors;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setAlert({ ...alert, [name]: value });
  };

  const handleSelectChange = (name: string, value: string) => {
    setAlert({ ...alert, [name]: value });
  };

  const handleManagerToggle = (manager: ManagerTypeValue) => {
    const newManagers = alert.managers.includes(manager)
      ? alert.managers.filter((m) => m !== manager)
      : [...alert.managers, manager];

    setAlert({ ...alert, managers: newManagers });
  };

  const handleEnvironmentToggle = (env: EnvironmentTypeValue) => {
    const newEnvironment = alert.environment.includes(env)
      ? alert.environment.filter((e) => e !== env)
      : [...alert.environment, env];

    setAlert({ ...alert, environment: newEnvironment });
  };

  const handleDateChange = (name: string, date: Date | undefined) => {
    setAlert({ ...alert, [name]: date ? date.toISOString() : "" });
  };

  useEffect(() => {
    setAlert({
      ...alert,
      csids: selectedStores.map((store) => store.csid),
    });
  }, [selectedStores]);

  const generateMarkdown = () => {
    const validationErrors = validateAlert();
    setErrors(validationErrors);

    if (validationErrors.length > 0) {
      return;
    }

    const suggestedFileName =
      alert.message
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "-")
        .replace(/-+/g, "-")
        .replace(/^-|-$/g, "") + ".md";

    setFileName(suggestedFileName);
    setAlertFileName(suggestedFileName);

    const frontMatter = [
      "---",
      `id: ${alert.id}`,
      `type: ${alert.type}`,
      `message: ${alert.message}`,
      `severity: ${alert.severity}`,
      `managers: [${alert.managers.map((m) => `"${m}"`).join(", ")}]`,
      `csids: [${alert.csids.map((c) => `"${c}"`).join(", ")}]`,
      `environment: [${alert.environment.map((e) => `"${e}"`).join(", ")}]`,
      alert.startTime ? `startTime: "${alert.startTime}"` : "",
      alert.endTime ? `endTime: "${alert.endTime}"` : "",
      "---",
      "",
      alert.details,
    ]
      .filter(Boolean)
      .join("\n");

    setMarkdown(frontMatter);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(markdown);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const downloadMarkdown = () => {
    const blob = new Blob([markdown], { type: "text/markdown" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const formatDateForDisplay = (isoString: string) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const resetForm = () => {
    setAlert({
      id: generateUniqueId(),
      type: "info",
      message: "",
      details: "",
      severity: "medium",
      managers: [],
      csids: [],
      environment: ["production"],
      startTime: new Date().toISOString(),
      endTime: "",
    });
    setSelectedStores([]);
    setMarkdown("");
    setErrors([]);
    setAlertFileName("");
    setFileName("");
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Alert Creator</CardTitle>
          <CardDescription>
            Create markdown alerts for the alert system
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-4">
          {errors.length > 0 && (
            <Alert variant="destructive">
              <AlertTitle>Validation Errors</AlertTitle>
              <AlertDescription>
                <ul className="list-disc pl-5">
                  {errors.map((error, index) => (
                    <li key={index}>{error}</li>
                  ))}
                </ul>
              </AlertDescription>
            </Alert>
          )}

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label htmlFor="type">Alert Type</Label>
              <Select
                value={alert.type}
                onValueChange={(value) => handleSelectChange("type", value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select type" />
                </SelectTrigger>
                <SelectContent>
                  {validTypes.map((type) => (
                    <SelectItem key={type} value={type}>
                      {type}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>

            <div className="space-y-2">
              <Label htmlFor="severity">Severity</Label>
              <Select
                value={alert.severity}
                onValueChange={(value) => handleSelectChange("severity", value)}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select severity" />
                </SelectTrigger>
                <SelectContent>
                  {validSeverity.map((severity) => (
                    <SelectItem key={severity} value={severity}>
                      {severity}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="message">Title (required)</Label>
            <Input
              id="message"
              name="message"
              value={alert.message}
              onChange={handleInputChange}
              placeholder="Alert title"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="details">Details (markdown)</Label>
            <Textarea
              id="details"
              name="details"
              value={alert.details}
              onChange={handleInputChange}
              placeholder="## Impact
- Item 1
- Item 2

## Workaround
Steps to work around the issue..."
              rows={6}
            />
          </div>

          <div className="space-y-2">
            <Label>Managers</Label>
            <div className="grid grid-cols-5 gap-2">
              {validManagers.map((manager) => (
                <div key={manager} className="flex items-center space-x-2">
                  <Checkbox
                    id={`manager-${manager}`}
                    checked={alert.managers.includes(
                      manager as ManagerTypeValue
                    )}
                    onCheckedChange={() =>
                      handleManagerToggle(manager as ManagerTypeValue)
                    }
                  />
                  <Label htmlFor={`manager-${manager}`}>{manager}</Label>
                </div>
              ))}
            </div>
            {alert.managers.length === 0 && (
              <div className="pt-6">
                <Alert variant="sky">
                  <AlertTitle>All managers will be affected</AlertTitle>
                  <AlertDescription>
                    No specific managers selected. This alert will apply to all
                    advertising managers.
                  </AlertDescription>
                </Alert>
              </div>
            )}
          </div>

          <div className="space-y-2">
            <Label>CSIDs</Label>
            <ComboboxMultiSelect
              selectedItems={
                selectedStores?.map((store) => ({
                  id: store.csid,
                  name: store.url,
                  tooltipContent: `CSID: ${store.csid}, URL: ${store.url}`,
                })) || []
              }
              items={
                stores?.map((store) => ({
                  id: store.csid,
                  name: store.url,
                  tooltipContent: `CSID: ${store.csid}, URL: ${store.url}`,
                })) || []
              }
              onItemSelect={(item) => {
                const store = stores?.find((s) => s.csid === item.id);
                if (store) {
                  setSelectedStores([...selectedStores, store]);
                }
              }}
              onItemRemove={(itemId) => {
                setSelectedStores(
                  selectedStores.filter((s) => s.csid !== itemId)
                );
              }}
              placeholder="No CSIDs selected yet"
              emptyStateText="No stores found."
              searchInputPlaceholder="Search stores by CSID or URL..."
              badgeVariant="purple"
              onClearAll={() => setSelectedStores([])}
              showClearAll
            />
            {selectedStores.length === 0 && (
              <div className="pt-6">
                <Alert variant="sky">
                  <AlertTitle>All stores will be affected</AlertTitle>
                  <AlertDescription>
                    No specific CSIDs selected. This alert will apply to all
                    stores.
                  </AlertDescription>
                </Alert>
              </div>
            )}
          </div>

          <div className="space-y-2">
            <Label>Environment (required)</Label>
            <div className="grid grid-cols-2 gap-2">
              {validEnvironments.map((env) => (
                <div key={env} className="flex items-center space-x-2">
                  <Checkbox
                    id={`env-${env}`}
                    checked={alert.environment.includes(
                      env as EnvironmentTypeValue
                    )}
                    onCheckedChange={() =>
                      handleEnvironmentToggle(env as EnvironmentTypeValue)
                    }
                  />
                  <Label htmlFor={`env-${env}`}>{env}</Label>
                </div>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <Label>Start Time</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="w-full justify-start text-left"
                  >
                    {alert.startTime
                      ? formatDateForDisplay(alert.startTime)
                      : "Select date"}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    // @ts-ignore
                    mode="single"
                    selected={
                      alert.startTime ? new Date(alert.startTime) : undefined
                    }
                    // @ts-ignore
                    onSelect={(date) => handleDateChange("startTime", date)}
                  />
                </PopoverContent>
              </Popover>
            </div>

            <div className="space-y-2">
              <Label>End Time</Label>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className="w-full justify-start text-left"
                  >
                    {alert.endTime
                      ? formatDateForDisplay(alert.endTime)
                      : "Select date"}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    // @ts-ignore
                    mode="single"
                    selected={
                      alert.endTime ? new Date(alert.endTime) : undefined
                    }
                    // @ts-ignore
                    onSelect={(date) => handleDateChange("endTime", date)}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>
        </CardContent>
        <CardFooter className="flex justify-between">
          <Button variant="outline" onClick={resetForm}>
            Reset Form
          </Button>
          <Button onClick={generateMarkdown}>Generate Alert</Button>
        </CardFooter>
      </Card>

      {markdown && (
        <Card>
          <CardHeader>
            <CardTitle>Generated Alert</CardTitle>
            <CardDescription>{alertFileName}</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="relative">
              <Textarea value={markdown} readOnly className="font-mono h-64" />
              <div className="absolute top-2 right-2 flex space-x-2">
                <Button variant="secondary" size="sm" onClick={copyToClipboard}>
                  {copied ? "Copied!" : "Copy"}
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={downloadMarkdown}
                >
                  Download
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default AlertCreator;
