import React, { useState } from "react";
import { Trash2, X, Plus } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Badge } from "@/components/ui/badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";

export type Item = {
  id: string;
  name: string;
  tooltipContent?: string;
};

type ComboboxMultiSelectProps = {
  selectedItems: Item[];
  items: Item[];
  onItemSelect: (item: Item) => void;
  onItemRemove: (itemId: string) => void;
  onClearAll?: () => void;
  onAddAll?: (items: Item[]) => void;
  placeholder: string;
  emptyStateText: string;
  searchInputPlaceholder: string;
  badgeVariant?: string;
  showClearAll?: boolean;
  closeOnSelect?: boolean;
};

const ComboboxMultiSelect = ({
  selectedItems,
  items,
  onItemSelect,
  onItemRemove,
  onClearAll,
  onAddAll,
  placeholder,
  emptyStateText,
  searchInputPlaceholder,
  badgeVariant = "default",
  showClearAll = false,
  closeOnSelect = false,
}: ComboboxMultiSelectProps) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const filteredItems = items.filter(
    (item) =>
      !selectedItems.some((selected) => selected.id === item.id) &&
      (item.name.toLowerCase().includes(inputValue.toLowerCase()) ||
        item.id.toLowerCase().includes(inputValue.toLowerCase()))
  );

  const handleSelect = (item: Item) => {
    onItemSelect(item);
    setInputValue("");
    if (closeOnSelect) {
      setOpen(false);
    }
  };

  const handleAddAll = () => {
    console.log("Click");
    if (onAddAll && filteredItems.length > 0) {
      onAddAll(filteredItems);
      setInputValue("");
      if (closeOnSelect) {
        setOpen(false);
      }
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <div className="relative p-2 border border-input rounded-md flex gap-2 flex-wrap min-h-[42px] cursor-text">
            {selectedItems.length > 0 ? (
              <>
                {selectedItems.map((item) => (
                  <TooltipProvider key={item.id}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <Badge
                          variant={badgeVariant as any}
                          className="cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            onItemRemove(item.id);
                          }}
                        >
                          {item.name}
                          <X className="ml-1 h-3 w-3" />
                        </Badge>
                      </TooltipTrigger>
                      {item.tooltipContent && (
                        <TooltipContent>
                          <p>{item.tooltipContent}</p>
                        </TooltipContent>
                      )}
                    </Tooltip>
                  </TooltipProvider>
                ))}
                {showClearAll && selectedItems.length > 0 && (
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-6 w-6 absolute right-2 top-1/2 -translate-y-1/2"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClearAll?.();
                    }}
                  >
                    <Trash2 className="h-4 w-4 text-muted-foreground hover:text-destructive" />
                  </Button>
                )}
              </>
            ) : (
              <span className="text-xs py-2 text-muted-foreground">
                {placeholder}
              </span>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent
          className="p-0 w-[--radix-popover-trigger-width]"
          align="start"
        >
          <Command className="border-0">
            <CommandInput
              placeholder={searchInputPlaceholder}
              value={inputValue}
              onValueChange={setInputValue}
            />
            <CommandList>
              <CommandEmpty>{emptyStateText}</CommandEmpty>
              <CommandGroup>
                {filteredItems.map((item) => (
                  <CommandItem
                    key={item.id}
                    onSelect={() => handleSelect(item)}
                  >
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <span className="truncate">
                            {item.name} - {item.id}
                          </span>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{item.tooltipContent}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
            {filteredItems.length > 0 && onAddAll && (
              <div className="border-t p-2">
                <Button
                  variant="secondary"
                  size="sm"
                  className="w-full flex items-center justify-center"
                  onClick={handleAddAll}
                >
                  <Plus className="mr-2 h-4 w-4" />
                  Add all ({filteredItems.length})
                </Button>
              </div>
            )}
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ComboboxMultiSelect;
