import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, batch } from "react-redux";
import {
  Plus,
  Search,
  RefreshCw,
  ChevronRight,
  ChevronLeft,
  Loader2,
  ChevronDown,
  ChevronUp,
  X,
  Copy,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
} from "@/components/ui/pagination";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Label } from "@/components/ui/label";

import { RootState, useAppDispatch as useDispatch } from "./store";
import { Account, Store } from "./backendTypes";
import { listStores } from "./reducers/stores";
import { createApproval, listApprovals } from "./reducers/approvals";
import { listAccounts } from "./reducers/accounts";
import {
  createOrganisation,
  listOrganisations,
} from "./reducers/organisations";
import TUtils from "./TUtils";
import { cn } from "./lib/utils";

import ComboboxMultiSelect from "./components/ComboboxMultiSelectProps/ComboboxMultiSelectProps";
import { toast } from "sonner";
import LinkedTableCell from "./components/LinkedTableCell/LinkedTableCell";

type SortDirection = "asc" | "desc";
type SortField = "id" | "name";

const headCells = [
  {
    id: "id",
    label: "Organisation-ID",
    sortable: true,
  },
  {
    id: "name",
    label: "Name",
    sortable: true,
  },
] as const;

function Organisations() {
  const scope = "kytron::read_events";
  const manageOrganisationsApprovalScope = "manage_organisations";

  // redux state
  const dispatch = useDispatch();
  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const chiefBackendUrl = useSelector(
    (state: RootState) => state.environment.chiefBackendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const account = useSelector((state: RootState) => state.user.account);
  const organisations = useSelector(
    (state: RootState) => state.organisations.organisations ?? []
  );
  const stores = useSelector((state: RootState) => state.stores.stores ?? []);
  const accounts = useSelector(
    (state: RootState) => state.accounts.accounts ?? []
  );
  const approvals = useSelector(
    (state: RootState) => state.approvals.approvals ?? []
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const [organisationName, setOrganisationName] = useState("");
  const [clientNumber, setClientNumber] = useState("");
  const [selectedStores, setSelectedStores] = useState<Store[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);

  const [addDlgOpen, setAddDlgOpen] = useState(false);

  const navigate = useNavigate();

  const searchText = searchParams.get("search") || "";
  // Sorting state from URL parameters
  const sortField = (searchParams.get("sortField") as SortField) || "id";
  const sortDirection =
    (searchParams.get("sortDirection") as SortDirection) || "asc";

  const organisationsWithStores = useMemo(() => {
    return organisations.map((organisation) => {
      const orgaStores = (organisation?.customer_sites ?? [])
        .map((site) => stores.find((el) => el.csid === site.id))
        .filter((el): el is Store => !!el?.csid);
      return { ...organisation, customer_sites: orgaStores };
    });
  }, [organisations, stores]);

  const [rows, setRows] = useState<typeof organisationsWithStores>([]);

  const sortedRows = useMemo(() => {
    if (!sortField) return rows;

    return [...rows].sort((a, b) => {
      const aValue = a[sortField]?.toString().toLowerCase() ?? "";
      const bValue = b[sortField]?.toString().toLowerCase() ?? "";

      if (sortDirection === "asc") {
        return aValue.localeCompare(bValue);
      }
      return bValue.localeCompare(aValue);
    });
  }, [rows, sortField, sortDirection]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const promises = [];
        if (stores.length === 0) {
          promises.push(dispatch(listStores({ scope, session, backendUrl })));
        }
        if (accounts.length === 0) {
          promises.push(dispatch(listAccounts({ session, backendUrl })));
        }
        if (organisations.length === 0) {
          promises.push(
            dispatch(
              listOrganisations({ session, backendUrl: chiefBackendUrl })
            )
          );
        }
        if (approvals.length === 0) {
          promises.push(dispatch(listApprovals({ session, backendUrl })));
        }

        if (promises.length > 0) {
          await Promise.all(promises);
        }
      } catch (error) {
        toast("Failed to fetch data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (searchText) {
      setRows(TUtils.filterArrayByString(organisationsWithStores, searchText));
      setPage(0);
    } else {
      setRows(organisationsWithStores);
    }
  }, [organisationsWithStores, searchText]);

  const manageOrgasApproval = useMemo(
    () =>
      !!account?.scp.any?.find((el) => el === manageOrganisationsApprovalScope),
    [account]
  );

  const handleSort = (field: SortField) => {
    let newDirection: SortDirection = "asc";
    if (field === sortField) {
      newDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("sortField", field);
      params.set("sortDirection", newDirection);
      return params;
    });
  };

  const getSortIcon = (field: string) => {
    if (field !== sortField) return null;
    return sortDirection === "asc" ? (
      <ChevronUp className="h-4 w-4 inline-block ml-1" />
    ) : (
      <ChevronDown className="h-4 w-4 inline-block ml-1" />
    );
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      if (searchValue) {
        params.set("search", searchValue);
      } else {
        params.delete("search");
      }
      return params;
    });
  };

  const handleUpdate = () => {
    setIsLoading(true);
    Promise.all([
      dispatch(listOrganisations({ session, backendUrl: chiefBackendUrl })),
      dispatch(listStores({ scope, session, backendUrl })),
      dispatch(listAccounts({ session, backendUrl })),
      dispatch(listApprovals({ session, backendUrl })),
    ]).finally(() => {
      setIsLoading(false);
    });
  };

  const handleAddAccountsToStores = () => {
    const newApprovals: any[] = [];
    selectedStores.forEach((store) => {
      selectedAccounts.forEach((account) => {
        const approval = approvals.find(
          (appr) =>
            appr.scope === scope &&
            appr.acid === account.acid &&
            appr.rsid === store.csid
        );
        if (!approval) {
          newApprovals.push(
            dispatch(
              createApproval({
                acid: account.acid,
                scope,
                rsid: store.csid,
                rstype: 0,
                session,
                backendUrl,
              })
            )
          );
        }
      });
    });
    return Promise.all(newApprovals);
  };

  const handleCreateOrganisation = async () => {
    try {
      const createResponse = await dispatch(
        createOrganisation({
          name: organisationName,
          client_number: clientNumber,
          customer_sites: selectedStores.map((el) => ({ id: el.csid })),
          accounts: selectedAccounts.map((el) => ({ id: el.acid })),
          session,
          backendUrl: chiefBackendUrl,
          feature_flags: {},
        })
      ).unwrap();

      await handleAddAccountsToStores();

      setAddDlgOpen(false);
      setSelectedAccounts([]);
      setSelectedStores([]);
      setOrganisationName("");
      setClientNumber("");
      handleUpdate();

      toast(
        `Organisation '${organisationName}' created with id '${createResponse.id}'`
      );

      navigate(`/organisations/${createResponse.id}`);
    } catch (err: any) {
      toast(
        `Unable to create organisation with name '${organisationName}': ${
          err.error || "Unknown error"
        }`
      );
    }
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle>Existing organisations</CardTitle>
          <div className="flex justify-between">
            <div className="flex items-center space-x-2 pt-3">
              <Search className="h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search organisations..."
                value={searchText}
                onChange={handleSearchChange}
                className="w-[300px]"
              />
            </div>

            <div className="mt-4 space-x-4">
              <Button
                variant="secondary"
                onClick={handleUpdate}
                className="gap-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <RefreshCw className="h-4 w-4" />
                )}
                Refresh
              </Button>

              {manageOrgasApproval && (
                <Button
                  onClick={() => setAddDlgOpen(true)}
                  className="gap-2"
                  disabled={isLoading}
                >
                  <Plus className="h-4 w-4" />
                  Add Organisation
                </Button>
              )}
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    {headCells.map((cell) => (
                      <TableHead
                        key={cell.id}
                        className={
                          cell.sortable ? "cursor-pointer select-none" : ""
                        }
                        onClick={() =>
                          cell.sortable && handleSort(cell.id as SortField)
                        }
                      >
                        <span className="flex items-center">
                          {cell.label}
                          {cell.sortable && getSortIcon(cell.id)}
                        </span>
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {sortedRows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.id} className="hover:bg-muted/50">
                        <LinkedTableCell
                          href={`/organisations/${row.id}`}
                          value={row.id}
                          onCopy={(value) => toast("ID copied to clipboard")}
                          stopPropagation={true}
                        />

                        <LinkedTableCell
                          href={`/organisations/${row.id}`}
                          value={row.name}
                          onCopy={(value) => toast("Name copied to clipboard")}
                        />
                      </TableRow>
                    ))}
                  {sortedRows.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={headCells.length}
                        className="text-center h-24"
                      >
                        No results found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={headCells.length}>
                      <div className="flex items-center flex-col justify-between py-2">
                        <Pagination>
                          <PaginationContent>
                            <PaginationItem>
                              <button
                                onClick={() => page > 0 && setPage(page - 1)}
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page === 0 && "opacity-50 cursor-not-allowed"
                                )}
                                disabled={page === 0}
                              >
                                <ChevronLeft className="h-4 w-4" />
                                Previous
                              </button>
                            </PaginationItem>

                            {[
                              ...Array(Math.ceil(rows.length / rowsPerPage)),
                            ].map((_, idx) => {
                              if (
                                idx === 0 ||
                                idx ===
                                  Math.ceil(rows.length / rowsPerPage) - 1 ||
                                (idx >= page - 2 && idx <= page + 2)
                              ) {
                                return (
                                  <PaginationItem key={idx}>
                                    <button
                                      onClick={() => setPage(idx)}
                                      className={cn(
                                        "px-3 py-1 rounded",
                                        page === idx
                                          ? "bg-primary text-primary-foreground"
                                          : "hover:bg-muted"
                                      )}
                                    >
                                      {idx + 1}
                                    </button>
                                  </PaginationItem>
                                );
                              } else if (
                                idx === 1 ||
                                idx === Math.ceil(rows.length / rowsPerPage) - 2
                              ) {
                                return (
                                  <PaginationItem key={idx}>
                                    <PaginationEllipsis />
                                  </PaginationItem>
                                );
                              }
                              return null;
                            })}

                            <PaginationItem>
                              <button
                                onClick={() =>
                                  page <
                                    Math.ceil(rows.length / rowsPerPage) - 1 &&
                                  setPage(page + 1)
                                }
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page >=
                                    Math.ceil(rows.length / rowsPerPage) - 1 &&
                                    "opacity-50 cursor-not-allowed"
                                )}
                                disabled={
                                  page >=
                                  Math.ceil(rows.length / rowsPerPage) - 1
                                }
                              >
                                Next
                                <ChevronRight className="h-4 w-4" />
                              </button>
                            </PaginationItem>
                          </PaginationContent>
                        </Pagination>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </>
          )}
        </CardContent>
      </Card>
      {addDlgOpen && (
        <Dialog open={addDlgOpen} onOpenChange={setAddDlgOpen}>
          <DialogContent className="sm:max-w-[600px]">
            <DialogHeader>
              <DialogTitle>Add Organisation</DialogTitle>
              <DialogDescription>
                Please provide the corresponding organisation info here:
              </DialogDescription>
            </DialogHeader>

            <div className="grid gap-4 py-4">
              <div className="space-y-2 text-foreground">
                <Label htmlFor="name">Company name</Label>
                <Input
                  id="name"
                  required
                  value={organisationName}
                  onChange={(e) => setOrganisationName(e.target.value)}
                />
              </div>

              <div className="space-y-2 text-foreground">
                <Label htmlFor="clientNumber">Client Number</Label>
                <Input
                  id="clientNumber"
                  value={clientNumber}
                  onChange={(e) => setClientNumber(e.target.value)}
                />
              </div>
              <div className="space-y-2 text-foreground">
                <Label>Stores</Label>
                <ComboboxMultiSelect
                  selectedItems={selectedStores.map((store) => ({
                    id: store.csid,
                    name: store.name,
                    tooltipContent: `CSID: ${store.csid}`,
                  }))}
                  items={stores.map((store) => ({
                    id: store.csid,
                    name: store.name,
                    tooltipContent: `CSID: ${store.csid}`,
                  }))}
                  onItemSelect={(item) => {
                    const store = stores.find((s) => s.csid === item.id);
                    if (store) {
                      setSelectedStores([...selectedStores, store]);
                    }
                  }}
                  onItemRemove={(itemId) => {
                    setSelectedStores(
                      selectedStores.filter((s) => s.csid !== itemId)
                    );
                  }}
                  placeholder="No stores selected yet"
                  emptyStateText="No stores found."
                  searchInputPlaceholder="Search stores..."
                  badgeVariant="purple"
                />
              </div>
              <div className="space-y-2 text-foreground">
                <Label>Accounts</Label>
                <ComboboxMultiSelect
                  selectedItems={selectedAccounts.map((account) => ({
                    id: account.acid,
                    name: account.email,
                  }))}
                  items={accounts.map((account) => ({
                    id: account.acid,
                    name: account.email,
                  }))}
                  onItemSelect={(item) => {
                    const account = accounts.find((a) => a.acid === item.id);
                    if (account) {
                      setSelectedAccounts([...selectedAccounts, account]);
                    }
                  }}
                  onItemRemove={(itemId) => {
                    setSelectedAccounts(
                      selectedAccounts.filter((a) => a.acid !== itemId)
                    );
                  }}
                  placeholder="No accounts selected yet"
                  emptyStateText="No accounts found."
                  searchInputPlaceholder="Search accounts..."
                  badgeVariant="sky"
                />
              </div>
            </div>

            <DialogFooter>
              <Button variant="outline" onClick={() => setAddDlgOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={handleCreateOrganisation}
                disabled={organisationName.length < 4}
              >
                Create
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

export default Organisations;
