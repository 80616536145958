import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { APIError, UTM, UTMListRequest } from "../backendTypes";

export interface UTMFilters {
  by_accounts?: Array<{
    account_id: string;
    source: string;
  }>;
  by_account_id?: string;
  by_sources?: Array<string>;
}

interface UTMSorting {
  sorting?: Array<string>;
}

const initialState: {
  utms: Array<UTM>;
  totalCount: number;
  utmError: string;
  loading: boolean;
  filters: UTMFilters;
  sorting: string[];
  currentRequestId: string | undefined;
} = {
  utms: [],
  totalCount: 0,
  utmError: "",
  loading: false,
  filters: {},
  sorting: [],
  currentRequestId: undefined,
};

let activeAbortController: AbortController | null = null;

export const listUtms = createAsyncThunk<
  { items: Array<UTM>; totalCount: number },
  UTMListRequest & {
    accounts?: (string | { account_id: string; source: string })[];
    filters?: UTMFilters;
    sorting?: string[];
    abortController?: boolean;
  },
  {
    rejectValue: APIError;
    requestId: string;
  }
>("utm/list", async (requestData, { rejectWithValue, signal, requestId }) => {
  try {
    if (activeAbortController && requestData.abortController) {
      activeAbortController.abort();
    }

    activeAbortController = new AbortController();

    const abortSignal = activeAbortController.signal;

    signal.addEventListener("abort", () => {
      activeAbortController?.abort();
    });

    const queryParams = new URLSearchParams();

    if (requestData.accounts) {
      requestData.accounts.forEach((account) => {
        if (typeof account === "string") {
          queryParams.append("account", account);
        } else {
          queryParams.append("account", `${account.account_id}`);
        }
      });
    }

    queryParams.append("limit", (requestData.limit || 20).toString());
    queryParams.append("offset", (requestData.offset || 0).toString());

    if (requestData.filters) {
      if (requestData.filters.by_accounts) {
        requestData.filters.by_accounts.forEach((account) => {
          queryParams.append("account_id", `${account.account_id}`);
        });
      }

      if (requestData.filters.by_account_id) {
        queryParams.append("account_id", requestData.filters.by_account_id);
      }

      if (requestData.filters.by_sources) {
        requestData.filters.by_sources.forEach((source) => {
          queryParams.append("source", source);
        });
      }
    }

    if (requestData.sorting) {
      requestData.sorting.forEach((sort) => {
        queryParams.append("sorting", sort);
      });
    }

    const response = await axios.get(
      `${
        requestData.backendUrl
      }/v1/tracking-parameters/list?${queryParams.toString()}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "tracify-token": requestData.session,
        },
        signal: abortSignal,
      }
    );

    if (response.status !== 200) {
      return rejectWithValue({ error: response.data.error } as APIError);
    }

    const totalCount = response.data.count;

    const items = Array.isArray(response.data.items) ? response.data.items : [];

    const processedItems = items.map((utm: any) => {
      const statusMap: Record<string, string> = {
        actove: "active",
        invalid: "Inactive",
      };

      const utmStatus = utm.status || "Inactive";
      const adStatus = utm.ad?.status || "Inactive";
      const effectiveStatus =
        statusMap[utmStatus] || statusMap[adStatus] || "Inactive";

      return {
        ...utm,
        status: effectiveStatus,
        errors: Array.isArray(utm.errors)
          ? utm.errors
          : utm.errors
          ? [utm.errors]
          : [],
        source:
          utm.ad?.source || utm.source || utm.account?.source || "Unknown",
      };
    }) as Array<UTM>;

    return {
      items: processedItems,
      totalCount: totalCount,
    };
  } catch (err) {
    if (axios.isAxiosError(err) && err.name === "CanceledError") {
      throw err;
    }

    if (axios.isAxiosError(err)) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue({
        error: err.response.data.error,
      } as APIError);
    }
    return rejectWithValue({
      error: "Unknown error occurred!",
    } as APIError);
  }
});

export const utmsSlice = createSlice({
  name: "utms",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setSorting: (state, action) => {
      state.sorting = action.payload;
    },
    cancelCurrentRequest: (state) => {
      if (activeAbortController) {
        activeAbortController.abort();
        activeAbortController = null;
      }
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listUtms.pending, (state, action) => {
        state.loading = true;
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(listUtms.fulfilled, (state, action) => {
        if (state.currentRequestId === action.meta.requestId) {
          state.utms = action.payload.items;
          state.totalCount = action.payload.totalCount;
          state.loading = false;
          state.utmError = "";
          state.currentRequestId = undefined;
        }
      })
      .addCase(listUtms.rejected, (state, action) => {
        if (
          state.currentRequestId === action.meta.requestId ||
          !action.meta.requestId
        ) {
          state.loading = false;
          if (action.payload) {
            state.utmError = action.payload.error;
          } else if (action.error.name !== "CanceledError") {
            state.utmError = action.error.message
              ? action.error.message
              : "unknown error";
          }
          state.currentRequestId = undefined;
        }
      });
  },
});

export const { setFilters, setSorting, cancelCurrentRequest } =
  utmsSlice.actions;
export default utmsSlice.reducer;
