import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  Search,
  RefreshCw,
  ChevronRight,
  ChevronLeft,
  Loader2,
  ChevronDown,
  ChevronUp,
  Info,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableFooter,
} from "@/components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
} from "@/components/ui/pagination";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";
import { DataFilter, DataFilterOption } from "@/components/ui/data-filter";

import { RootState, useAppDispatch as useDispatch } from "./store";
import { listUtms } from "./reducers/utms";
import { UTM } from "./backendTypes";
import { toast } from "sonner";
import { cn } from "./lib/utils";
import LinkedTableCell from "./components/LinkedTableCell/LinkedTableCell";
import ContentTableCell from "./components/ContentTableCell/ContentTableCell";

interface HeadCell {
  id: string;
  label: string;
  sortable: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "source",
    label: "Platform",
    sortable: false,
  },
  {
    id: "account.account_id",
    label: "Account ID",
    sortable: false,
  },
  {
    id: "accout.csid",
    label: "CSID",
    sortable: false,
  },
  {
    id: "content",
    label: "Content",
    sortable: false,
  },
  {
    id: "ad.ad_id",
    label: "Ad ID",
    sortable: false,
  },
  {
    id: "last_checked_at",
    label: "Last Checked at",
    sortable: true,
  },
  {
    id: "errors",
    label: "Errors",
    sortable: false,
  },
];

function TrackingSetup() {
  const dispatch = useDispatch();
  const adconnectorBackendUrl = useSelector(
    (state: RootState) => state.environment.adconnectorBackendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const utms = useSelector((state: RootState) => state.utms.utms ?? []);
  const totalCount = useSelector(
    (state: RootState) => state.utms.totalCount ?? 0
  );
  const isReduxLoading = useSelector((state: RootState) => state.utms.loading);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasInitiallyLoaded, setHasInitiallyLoaded] = useState<boolean>(false);
  const [rowsPerPage] = useState<number>(20);

  const searchTerm = searchParams.get("search") || "";
  const sourceFiltersParam = searchParams.get("source");
  const sourceFilters = sourceFiltersParam ? sourceFiltersParam.split(",") : [];
  const accountFilter = searchParams.get("account");
  const pageParam = searchParams.get("page");
  const page = pageParam ? parseInt(pageParam) : 0;
  const sortParam = searchParams.get("sort");
  const sortField = sortParam
    ? sortParam.startsWith("-")
      ? sortParam.substring(1)
      : sortParam
    : null;
  const sortDirection = sortParam && sortParam.startsWith("-") ? "desc" : "asc";

  const [localSearchTerm, setLocalSearchTerm] = useState<string>(searchTerm);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (localSearchTerm !== searchTerm) {
        updateUrlParams({ search: localSearchTerm || null, page: "0" });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [localSearchTerm]);

  const availableSources = useMemo((): DataFilterOption[] => {
    return ["facebook", "google", "tiktok", "pinterest", "outbrain"].map(
      (source) => ({
        label: source,
        value: source,
      })
    );
  }, []);

  const filteredUtms = useMemo(() => {
    return utms.filter((utm) => {
      if (sourceFilters.length > 0 && !sourceFilters.includes(utm.source)) {
        return false;
      }
      return true;
    });
  }, [utms, sourceFilters]);

  const updateUrlParams = (updates: Record<string, string | null>) => {
    const newParams = new URLSearchParams(searchParams);

    Object.entries(updates).forEach(([key, value]) => {
      if (value === null || value === "") {
        newParams.delete(key);
      } else {
        newParams.set(key, value);
      }
    });

    setSearchParams(newParams);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearchTerm(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      updateUrlParams({ search: localSearchTerm || null, page: "0" });
    }
  };

  const handleSortChange = (cellId: string) => {
    const newDirection =
      cellId === sortField && sortDirection === "asc" ? "desc" : "asc";

    updateUrlParams({
      sort: newDirection === "desc" ? `-${cellId}` : cellId,
    });
  };

  const handleSourceFilterChange = (values: string[]) => {
    updateUrlParams({
      source: values.length > 0 ? values.join(",") : null,
      page: "0",
    });
  };

  const handlePageChange = (newPage: number) => {
    updateUrlParams({
      page: newPage > 0 ? newPage.toString() : null,
    });
  };

  const handleRefresh = () => {
    fetchData();
  };

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const filters: {
        by_accounts?: Array<{ account_id: string; source: string }>;
        by_sources?: Array<string>;
        by_account_id?: string;
      } = {};

      if (accountFilter && sourceFilters.length > 0) {
        filters.by_accounts = [
          {
            account_id: accountFilter,
            source: sourceFilters[0],
          },
        ];
      }

      if (sourceFilters.length > 0) {
        filters.by_sources = sourceFilters;
      }

      if (searchTerm) {
        filters.by_account_id = searchTerm;
      }

      const sortingArray: string[] = [];
      if (sortField) {
        const sortParam =
          sortDirection === "desc" ? `-${sortField}` : sortField;
        sortingArray.push(sortParam);
      }

      await dispatch(
        listUtms({
          session,
          backendUrl: adconnectorBackendUrl,
          limit: rowsPerPage,
          offset: page * rowsPerPage,
          filters: Object.keys(filters).length > 0 ? filters : undefined,
          sorting: sortingArray.length > 0 ? sortingArray : undefined,
          abortController: true,
        })
      );
    } catch (error) {
      toast("Failed to fetch UTM data. Please try again.");
    } finally {
      setIsLoading(false);
      setHasInitiallyLoaded(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    setLocalSearchTerm(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    if (!isReduxLoading && isLoading) {
      setIsLoading(false);
      setHasInitiallyLoaded(true);
    }
  }, [isReduxLoading, isLoading]);

  const formatDate = (dateString?: string): string => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  const renderErrorTooltip = (errors?: any[]) => {
    if (!errors || !Array.isArray(errors) || errors.length === 0)
      return "No errors";

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="flex items-center">
              <Badge variant="rose" className="mr-1">
                {errors.length}
              </Badge>
              <Info className="h-4 w-4 text-muted-foreground" />
            </div>
          </TooltipTrigger>
          <TooltipContent className="max-w-sm">
            <ul className="list-disc list-inside">
              {errors.map((error, index) => (
                <li key={index} className="text-sm mb-1">
                  {typeof error === "string" ? error : JSON.stringify(error)}
                </li>
              ))}
            </ul>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  const totalPages = Math.max(1, Math.ceil(totalCount / rowsPerPage));
  const showLoader = isLoading || isReduxLoading || !hasInitiallyLoaded;

  return (
    <div>
      <Card>
        <CardHeader>
          <CardTitle className="mb-4">Tracking Parameters</CardTitle>
          <div className="flex flex-col gap-4 sm:flex-row sm:justify-between">
            <div className="flex flex-col sm:flex-row sm:items-center gap-2">
              <div className="flex flex-wrap gap-2">
                <div className="flex items-center">
                  <div className="relative">
                    <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                    <Input
                      className="pl-8 w-60"
                      placeholder="Search by Account ID"
                      value={localSearchTerm}
                      onChange={handleSearchChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>

                <DataFilter
                  title="Platform"
                  withInput={false}
                  options={availableSources}
                  value={sourceFilters}
                  onValueChange={handleSourceFilterChange}
                />
              </div>
            </div>

            <div className="space-x-4">
              <Button
                variant="secondary"
                onClick={handleRefresh}
                className="gap-2"
                disabled={showLoader}
              >
                {showLoader ? (
                  <Loader2 className="h-4 w-4 animate-spin" />
                ) : (
                  <RefreshCw className="h-4 w-4" />
                )}
                Refresh
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardContent>
          {showLoader ? (
            <div className="flex items-center justify-center h-64">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <>
              <Table>
                <TableHeader>
                  <TableRow>
                    {headCells.map((cell) => (
                      <TableHead
                        key={cell.id}
                        className={
                          cell.sortable ? "cursor-pointer select-none" : ""
                        }
                        onClick={() => {
                          if (cell.sortable) {
                            handleSortChange(cell.id);
                          }
                        }}
                      >
                        <div className="flex items-center">
                          {cell.label}
                          {cell.sortable &&
                            cell.id === sortField &&
                            (sortDirection === "asc" ? (
                              <ChevronUp className="ml-1 h-4 w-4" />
                            ) : (
                              <ChevronDown className="ml-1 h-4 w-4" />
                            ))}
                        </div>
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredUtms.map((row, index) => (
                    <TableRow
                      key={`${row.ad?.account_id || index}-${
                        row.ad?.ad_id || index
                      }-${index}`}
                      data-id={`${row.id}`}
                      className="hover:bg-muted/50"
                    >
                      <TableCell className="capitalize">{row.source}</TableCell>
                      <LinkedTableCell
                        value={row.account.account_id || "-"}
                        onCopy={(value) =>
                          toast("Account ID copied to clipboard")
                        }
                      />
                      <LinkedTableCell
                        value={row.account.csid || "-"}
                        onCopy={(value) => toast("CSID copied to clipboard")}
                        href={`/stores/${row.account.csid}`}
                      />
                      <ContentTableCell content={row.content} />

                      <LinkedTableCell
                        value={row.ad?.ad_id || "-"}
                        onCopy={(value) => toast("Ad ID copied to clipboard")}
                      />
                      <TableCell>{formatDate(row.last_checked_at)}</TableCell>
                      <TableCell>{renderErrorTooltip(row.errors)}</TableCell>
                    </TableRow>
                  ))}
                  {filteredUtms.length === 0 && (
                    <TableRow>
                      <TableCell
                        colSpan={headCells.length}
                        className="text-center h-24"
                      >
                        No results found.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={headCells.length}>
                      <div className="flex items-center justify-between py-2">
                        <div className="text-sm text-muted-foreground">
                          Showing {page * rowsPerPage + 1} to{" "}
                          {Math.min((page + 1) * rowsPerPage, totalCount)} of{" "}
                          {totalCount} results
                        </div>
                        <Pagination>
                          <PaginationContent>
                            <PaginationItem>
                              <button
                                onClick={() =>
                                  page > 0 && handlePageChange(page - 1)
                                }
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page === 0 && "opacity-50 cursor-not-allowed"
                                )}
                                disabled={page === 0}
                              >
                                <ChevronLeft className="h-4 w-4" />
                                Previous
                              </button>
                            </PaginationItem>

                            {Array.from({ length: totalPages }).map(
                              (_, idx) => {
                                if (
                                  idx === 0 ||
                                  idx === totalPages - 1 ||
                                  (idx >= page - 1 && idx <= page + 1)
                                ) {
                                  return (
                                    <PaginationItem key={idx}>
                                      <button
                                        onClick={() => handlePageChange(idx)}
                                        className={cn(
                                          "px-3 py-1 rounded",
                                          page === idx
                                            ? "bg-primary text-primary-foreground"
                                            : "hover:bg-muted"
                                        )}
                                      >
                                        {idx + 1}
                                      </button>
                                    </PaginationItem>
                                  );
                                } else if (
                                  (idx === 1 && page > 2) ||
                                  (idx === totalPages - 2 &&
                                    page < totalPages - 3)
                                ) {
                                  return (
                                    <PaginationItem key={idx}>
                                      <PaginationEllipsis />
                                    </PaginationItem>
                                  );
                                }
                                return null;
                              }
                            )}

                            <PaginationItem>
                              <button
                                onClick={() =>
                                  page < totalPages - 1 &&
                                  handlePageChange(page + 1)
                                }
                                className={cn(
                                  "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                  page >= totalPages - 1 &&
                                    "opacity-50 cursor-not-allowed"
                                )}
                                disabled={page >= totalPages - 1}
                              >
                                Next
                                <ChevronRight className="h-4 w-4" />
                              </button>
                            </PaginationItem>
                          </PaginationContent>
                        </Pagination>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
}

export default TrackingSetup;
