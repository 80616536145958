import React, { SetStateAction, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import {
  RefreshCw,
  Search,
  Download,
  Copy,
  ChevronsUpDown,
  Check,
  ChevronDown,
  ChevronUp,
  ChevronRight,
  ChevronLeft,
} from "lucide-react";

import { APIError, EventInfoExt, EventInfoTotals, Store } from "./backendTypes";
import { RootState, useAppDispatch as useDispatch } from "./store";
import { listEvents } from "./reducers/events";
import { listStores } from "./reducers/stores";
import { cn, timezones } from "./lib/utils";
import { toast } from "sonner";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./components/ui/command";
import StoreSelect from "./components/StoreSelect/StoreSelect";
import LinkedTableCell from "./components/LinkedTableCell/LinkedTableCell";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
} from "./components/ui/pagination";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./components/ui/select";

type SortDirection = "asc" | "desc";
type SortField = "guid" | "event" | "nitems" | "amount";

const headCells = [
  {
    id: "guid",
    label: "GUID",
    sortable: true,
  },
  {
    id: "event",
    label: "Timestamp",
    sortable: true,
  },
  {
    id: "nitems",
    label: "Num(items)",
    sortable: true,
  },
  {
    id: "amount",
    label: "Amount (EUR)",
    sortable: true,
  },
] as const;

function qualifiedStoreName(store: Store) {
  const name =
    store.url.length > 0
      ? `${store.name} - ${store.description} (${store.url})`
      : `${store.name} - ${store.description}`;
  return name + ` - cluster ${store.pg_idx + 1}`;
}

function OrderStatus() {
  const scope = "kytron::read_events";
  const dispatch = useDispatch();

  const backendUrl = useSelector(
    (state: RootState) => state.environment.backendUrl
  );
  const session = useSelector((state: RootState) => state.user.session);
  const stores = useSelector((state: RootState) => state.stores.stores);

  const [searchParams, setSearchParams] = useSearchParams();
  const [timezoneOpen, setTimezoneOpen] = useState(false);
  const [storeOpen, setStoreOpen] = useState(false);

  useEffect(() => {
    if (stores.length === 0) {
      dispatch(listStores({ scope, session, backendUrl }));
    }
  }, []);

  const defaultZone = DateTime.local().zoneName;
  const [selectedDates, setSelectedDates] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: new Date(),
    endDate: new Date(),
  });

  const [selectedTimezone, setSelectedTimezone] = useState<string>(defaultZone);
  const [orders, setOrders] = useState<Array<EventInfoExt>>([]);
  const [filteredOrders, setFilteredOrders] = useState<Array<EventInfoExt>>([]);
  const [totals, setTotals] = useState<EventInfoTotals | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedStoreName, setSelectedStoreName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");

  const sortField = (searchParams.get("sortField") as SortField) || "event";
  const sortDirection =
    (searchParams.get("sortDirection") as SortDirection) || "desc";

  const handleSort = (field: SortField) => {
    let newDirection: SortDirection = "asc";
    if (field === sortField) {
      newDirection = sortDirection === "asc" ? "desc" : "asc";
    }

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      params.set("sortField", field);
      params.set("sortDirection", newDirection);
      return params;
    });
  };

  const getSortIcon = (field: string) => {
    if (field !== sortField) return null;
    return sortDirection === "asc" ? (
      <ChevronUp className="h-4 w-4 inline-block ml-1" />
    ) : (
      <ChevronDown className="h-4 w-4 inline-block ml-1" />
    );
  };

  const handleUpdate = async () => {
    const selectedStores = stores.filter(
      (store) => qualifiedStoreName(store) === selectedStoreName
    );

    const csids = selectedStores.map((x) => x.csid);

    try {
      const orderResponse = await dispatch(
        listEvents({
          csids,
          interactions: ["purchase"],
          collect_start: collectStart,
          collect_end: collectEnd,
          session,
          backendUrl,
        })
      ).unwrap();

      setOrders(orderResponse.events);
      setTotals(orderResponse.totals);
      toast.success("Order information retrieved successfully");
    } catch (err: any) {
      const error = err as APIError;
      toast.error(`Unable to retrieve data: ${error.error}`);
      setErrorMessage(`Unable to retrieve data for store: ${error.error}`);
    }
  };

  useEffect(() => {
    if (searchText.length !== 0) {
      setFilteredOrders(
        orders.filter((order) =>
          Object.values(order).some((value) =>
            value.toString().toLowerCase().includes(searchText.toLowerCase())
          )
        )
      );
      setCurrentPage(0);
    } else {
      setFilteredOrders(orders);
    }
  }, [orders, searchText]);

  const collectStart = useMemo(() => {
    return DateTime.fromJSDate(selectedDates.startDate)
      .setZone(selectedTimezone)
      .startOf("day")
      .toUTC()
      .toFormat("yyyy-MM-dd HH:mm:ss");
  }, [selectedTimezone, selectedDates.startDate]);

  const collectEnd = useMemo(() => {
    return DateTime.fromJSDate(selectedDates.endDate)
      .setZone(selectedTimezone)
      .endOf("day")
      .toUTC()
      .toFormat("yyyy-MM-dd HH:mm:ss");
  }, [selectedTimezone, selectedDates.endDate]);

  const sortedOrders = useMemo(() => {
    if (!sortField) return filteredOrders;

    return [...filteredOrders].sort((a, b) => {
      let aValue: string | number;
      let bValue: string | number;

      switch (sortField) {
        case "guid":
          aValue = a.guid.toLowerCase();
          bValue = b.guid.toLowerCase();
          break;
        case "event":
          aValue = DateTime.fromFormat(a.event, "yyyy-MM-dd HH:mm:ss", {
            zone: "UTC",
          }).toMillis();
          bValue = DateTime.fromFormat(b.event, "yyyy-MM-dd HH:mm:ss", {
            zone: "UTC",
          }).toMillis();
          break;
        case "nitems":
          aValue = a.nitems;
          bValue = b.nitems;
          break;
        case "amount":
          aValue = a.amount;
          bValue = b.amount;
          break;
        default:
          return 0;
      }

      if (sortDirection === "asc") {
        return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
      }
      return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
    });
  }, [filteredOrders, sortField, sortDirection]);

  const dailyTotals = useMemo(() => {
    return orders.reduce((prev, curr) => {
      const date = DateTime.fromFormat(curr.event, "yyyy-MM-dd HH:mm:ss", {
        zone: "UTC",
      }).setZone(selectedTimezone);
      const key = date.toFormat("yyyy-MM-dd");

      const prevTotals = prev[key] || { nitems: 0, amount: 0, orders: 0 };
      prev[key] = {
        nitems: prevTotals.nitems + curr.nitems,
        amount: prevTotals.amount + curr.amount,
        orders: prevTotals.orders + 1,
      };
      return prev;
    }, {} as Record<string, EventInfoTotals>);
  }, [orders, selectedTimezone]);

  const exportToCSV = () => {
    const headers = ["GUID", "Timestamp", "Num(items)", "Amount (EUR)"];
    const rows = orders.map((x) => [
      x.guid,
      DateTime.fromFormat(x.event, "yyyy-MM-dd HH:mm:ss", { zone: "UTC" })
        .setZone(selectedTimezone)
        .toLocaleString(DateTime.DATETIME_FULL),
      x.nitems,
      x.amount.toFixed(2),
    ]);

    const csv = [headers, ...rows].map((x) => x.join(",")).join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `orders_${DateTime.fromJSDate(
      selectedDates.startDate
    ).toFormat("yyyy-MM-dd")}_${DateTime.fromJSDate(
      selectedDates.endDate
    ).toFormat("yyyy-MM-dd")}_${selectedStoreName}.csv`;
    a.click();
  };

  const exportTotalsToCSV = () => {
    const headers = ["Date", "Orders", "Amount (EUR)", "Num(items)"];
    const rows = Object.entries(dailyTotals).map(([key, value]) => [
      key,
      value.orders,
      value.amount.toFixed(2),
      value.nitems,
    ]);

    const csv = [headers, ...rows].map((x) => x.join(",")).join("\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `daily_totals_${DateTime.fromJSDate(
      selectedDates.startDate
    ).toFormat("yyyy-MM-dd")}_${DateTime.fromJSDate(
      selectedDates.endDate
    ).toFormat("yyyy-MM-dd")}_${selectedStoreName}.csv`;
    a.click();
  };

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Order Information</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-4">
            <div className="grid gap-4">
              <div className="flex gap-2">
                <StoreSelect
                  stores={stores}
                  selectedStoreName={selectedStoreName}
                  onStoreSelect={setSelectedStoreName}
                />
                <Popover open={timezoneOpen} onOpenChange={setTimezoneOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      aria-expanded={timezoneOpen}
                      className="w-full justify-between"
                    >
                      {selectedTimezone ?? "Select timezone..."}
                      <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-full p-0">
                    <Command>
                      <CommandInput placeholder="Search timezone..." />
                      <CommandList>
                        <CommandEmpty>No timezone found.</CommandEmpty>
                        <CommandGroup className="max-h-[200px] overflow-auto">
                          {timezones.map((timezone) => (
                            <CommandItem
                              key={timezone}
                              value={timezone}
                              onSelect={(currentValue) => {
                                setSelectedTimezone(
                                  currentValue === selectedTimezone
                                    ? ""
                                    : currentValue
                                );
                                setTimezoneOpen(false);
                              }}
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  selectedTimezone === timezone
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {timezone}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
              <div className="flex gap-2">
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline">
                      {selectedDates.startDate ? (
                        DateTime.fromJSDate(selectedDates.startDate).toFormat(
                          "LLL dd, yyyy"
                        )
                      ) : (
                        <span>Start Date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      // @ts-ignore
                      mode="single"
                      selected={selectedDates.startDate}
                      onSelect={(date: any) =>
                        date &&
                        setSelectedDates((prev) => ({
                          ...prev,
                          startDate: date,
                        }))
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>

                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline">
                      {selectedDates.endDate ? (
                        DateTime.fromJSDate(selectedDates.endDate).toFormat(
                          "LLL dd, yyyy"
                        )
                      ) : (
                        <span>End Date</span>
                      )}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      // @ts-ignore
                      mode="single"
                      selected={selectedDates.endDate}
                      onSelect={(date: any) =>
                        date &&
                        setSelectedDates((prev) => ({ ...prev, endDate: date }))
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>

                <Button
                  onClick={handleUpdate}
                  disabled={selectedStoreName.length < 1}
                >
                  <RefreshCw className="mr-2 h-4 w-4" />
                  Update
                </Button>
              </div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <div className="flex items-center space-x-2">
                <Search className="h-4 w-4 text-muted-foreground" />
                <Input
                  placeholder="Search orders..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="w-[300px]"
                />
              </div>

              <div className="space-x-2">
                <Button
                  variant="secondary"
                  onClick={exportToCSV}
                  disabled={selectedStoreName.length < 1}
                >
                  <Download className="mr-2 h-4 w-4" />
                  Export Orders
                </Button>
                <Button
                  variant="secondary"
                  onClick={exportTotalsToCSV}
                  disabled={selectedStoreName.length < 1}
                >
                  <Download className="mr-2 h-4 w-4" />
                  Export Daily Totals
                </Button>
              </div>
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  {headCells.map((cell) => (
                    <TableHead
                      key={cell.id}
                      className={
                        cell.sortable ? "cursor-pointer select-none" : ""
                      }
                      onClick={() =>
                        cell.sortable && handleSort(cell.id as SortField)
                      }
                    >
                      <span className="flex items-center">
                        {cell.label}
                        {cell.sortable && getSortIcon(cell.id)}
                      </span>
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {sortedOrders
                  .slice(
                    currentPage * rowsPerPage,
                    (currentPage + 1) * rowsPerPage
                  )
                  .map((order) => (
                    <TableRow key={`${order.guid}_${order.event}`}>
                      <LinkedTableCell
                        value={order.guid}
                        onCopy={(value) =>
                          toast("Order GUID copied to clipboard")
                        }
                      />
                      <LinkedTableCell
                        value={DateTime.fromFormat(
                          order.event,
                          "yyyy-MM-dd HH:mm:ss",
                          { zone: "UTC" }
                        )
                          .setZone(selectedTimezone)
                          .toLocaleString(DateTime.DATETIME_FULL)}
                        onCopy={(value) =>
                          toast("Timestamp copied to clipboard")
                        }
                      />
                      <LinkedTableCell
                        value={order.nitems.toString()}
                        className="text-right"
                        onCopy={(value) =>
                          toast("Items count copied to clipboard")
                        }
                      />
                      <LinkedTableCell
                        value={order.amount.toFixed(2)}
                        className="text-right"
                        onCopy={(value) => toast("Amount copied to clipboard")}
                      />
                    </TableRow>
                  ))}
                {sortedOrders.length === 0 && (
                  <TableRow>
                    <TableCell
                      colSpan={headCells.length}
                      className="text-center h-24"
                    >
                      No orders found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell colSpan={headCells.length}>
                    <div className="flex items-center flex-col justify-between py-2">
                      <div className="flex w-full justify-between items-center mb-4">
                        <div className="flex items-center gap-2">
                          <span className="text-sm text-muted-foreground">
                            Rows per page:
                          </span>
                          <Select
                            value={rowsPerPage.toString()}
                            onValueChange={(value) => {
                              setRowsPerPage(parseInt(value));
                              setCurrentPage(0);
                            }}
                          >
                            <SelectTrigger className="w-[100px]">
                              <SelectValue placeholder="10" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="10">10</SelectItem>
                              <SelectItem value="25">25</SelectItem>
                              <SelectItem value="50">50</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                        <span className="text-sm text-muted-foreground">
                          Showing {currentPage * rowsPerPage + 1} to{" "}
                          {Math.min(
                            (currentPage + 1) * rowsPerPage,
                            sortedOrders.length
                          )}{" "}
                          of {sortedOrders.length} entries
                        </span>
                      </div>
                      <Pagination>
                        <PaginationContent>
                          <PaginationItem>
                            <button
                              onClick={() =>
                                currentPage > 0 &&
                                setCurrentPage(currentPage - 1)
                              }
                              className={cn(
                                "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                currentPage === 0 &&
                                  "opacity-50 cursor-not-allowed"
                              )}
                              disabled={currentPage === 0}
                            >
                              <ChevronLeft className="h-4 w-4" />
                              Previous
                            </button>
                          </PaginationItem>

                          {[
                            ...Array(
                              Math.ceil(sortedOrders.length / rowsPerPage)
                            ),
                          ].map((_, idx) => {
                            if (
                              idx === 0 ||
                              idx ===
                                Math.ceil(sortedOrders.length / rowsPerPage) -
                                  1 ||
                              (idx >= currentPage - 2 && idx <= currentPage + 2)
                            ) {
                              return (
                                <PaginationItem key={idx}>
                                  <button
                                    onClick={() => setCurrentPage(idx)}
                                    className={cn(
                                      "px-3 py-1 rounded",
                                      currentPage === idx
                                        ? "bg-primary text-primary-foreground"
                                        : "hover:bg-muted"
                                    )}
                                  >
                                    {idx + 1}
                                  </button>
                                </PaginationItem>
                              );
                            } else if (
                              idx === 1 ||
                              idx ===
                                Math.ceil(sortedOrders.length / rowsPerPage) - 2
                            ) {
                              return (
                                <PaginationItem key={idx}>
                                  <PaginationEllipsis />
                                </PaginationItem>
                              );
                            }
                            return null;
                          })}

                          <PaginationItem>
                            <button
                              onClick={() =>
                                currentPage <
                                  Math.ceil(sortedOrders.length / rowsPerPage) -
                                    1 && setCurrentPage(currentPage + 1)
                              }
                              className={cn(
                                "px-2 py-1 rounded hover:bg-muted flex gap-1",
                                currentPage >=
                                  Math.ceil(sortedOrders.length / rowsPerPage) -
                                    1 && "opacity-50 cursor-not-allowed"
                              )}
                              disabled={
                                currentPage >=
                                Math.ceil(sortedOrders.length / rowsPerPage) - 1
                              }
                            >
                              Next
                              <ChevronRight className="h-4 w-4" />
                            </button>
                          </PaginationItem>
                        </PaginationContent>
                      </Pagination>
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-3 gap-4">
        <Card>
          <CardHeader>
            <CardTitle>Total Amount</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold">
                €{totals?.amount.toFixed(2) || "0.00"}
              </span>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  navigator.clipboard.writeText(
                    totals?.amount.toFixed(2) || "0.00"
                  );
                  toast.success("Amount copied to clipboard");
                }}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Total Items</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold">{totals?.nitems || 0}</span>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  navigator.clipboard.writeText(
                    totals?.nitems.toString() || "0"
                  );
                  toast.success("Items count copied to clipboard");
                }}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Total Orders</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center justify-between">
              <span className="text-2xl font-bold">{orders.length || 0}</span>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  navigator.clipboard.writeText(
                    orders.length.toString() || "0"
                  );
                  toast.success("Orders count copied to clipboard");
                }}
              >
                <Copy className="h-4 w-4" />
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      {Object.keys(dailyTotals).length > 0 && (
        <Card>
          <CardHeader>
            <CardTitle>Daily Breakdown</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="grid grid-cols-3 gap-4">
              {Object.entries(dailyTotals).map(([date, totals]) => (
                <Card key={date}>
                  <CardHeader>
                    <CardTitle>{date}</CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-2">
                    <div className="flex justify-between">
                      <span className="text-muted-foreground">Orders:</span>
                      <span className="font-medium">{totals.orders}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-muted-foreground">Items:</span>
                      <span className="font-medium">{totals.nitems}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-muted-foreground">Amount:</span>
                      <span className="font-medium">
                        €{totals.amount.toFixed(2)}
                      </span>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
}

export default OrderStatus;
